import React, { Component } from "react";

export default class DebugOverlay extends Component {
  render() {
    return (
      <div
        className="debugContainer"
        style={
          this.props.active
            ? {}
            : {
                position: "absolute",
                overflow: "hidden",
                clip: "rect(0 0 0 0)",
                height: " 1px; width: 1px",
                margin: "-1px",
                padding: 0,
                border: 0
              }
        }
      >
        <div className="grid" onMouseMove={this.props.updateMousePosition()} />
      </div>
    );
  }
}
