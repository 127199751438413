const content = [
  {
    title: "The Three Labors"
  },
  {
    text: `It is known in the wildlands below the ruined city of Faran Gol that, on the highest peak of the tallest
    mountain, among howling storms and dancing lightning bolts, the Thunder Tribe has dwelled since
    time immemorial.`
  },
  {
    text: `To be a ruler of the Thunder Tribe, one must endure the Three Labors of Leviathan. It is on this day
    that a young girl, barely a woman, takes on this trial.`
  },
  {
    text: `Her name is Freya. This is her tale.`
  },
  {
    text: `Freya closed her eyes as the bitter incense burned her nostrils and filled her lungs. The cave she sat
    in was dank and cold. Freya had known this day would come. The day of the Labors. Freya was next
    in line to become queen of the Thunder Tribe.`
  },
  {
    image:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/freya/pretty_windy.jpg"
  },
  {
    text: `When her Father passed two moons ago, Freya knew the trials were soon to follow. She had loved him
    dearly, but she had no time to grieve. Her thoughts held only trepidation of what was to come. Freya
    was a warrior born, she had no peer with the sword and shield, with the hammer or spear. But in the
    trials, it was said many were defeated without ever unsheathing a weapon.`
  },
  {
    text: `The incense did its work and sleep washed over Freya like a gentle breeze. She drifted from the land
    of the waking to where dreams and nightmares roamed.`
  },
  {
    text: `Freya opened her eyes and found herself in a vast dank bog. She looked up to see the sky the color of
    blood and a moon black as obsidian. The trees were pulsing, fleshy and horrific, shaped like slithering
    masses of coiling worms. From the trees hung dead woodland creatures: rabbits, mice, badgers,
    possums and the like. As Freya walked, the ground beneath her feet sucked at her boots as if wanting
    to hold her in place and consume her.`
  },

  {
    text: `Freya slogged through this nightmarish swamp and a great form began to take shape in the distance.
    Two massive yellow orbs sat on a broad flat head. Freya immediately recognized the creature as the
    snake god - Akrohnis. Freya clutched her blade tightly and cautiously approached the massive snake
    spirit.`
  },
  {
    image:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/freya/Freya02.jpg"
  },
  {
    text: `Akrohnis spoke, her voice halfway between a raspy hiss and a mother’s gentle whisper to a beloved
    child. Somehow it filled the entire swamp. "Freya of the Flaming Locks, you, like your ancestors
    before you, come to my realm seeking the rite of Rule. My test is not one of might nor
    swordsmanship, but cunning of mind. If you can solve my riddle, you shall gain passage through this
    realm, into the realms of my brothers. But if you fail, I will suck the flesh off your bones, and digest
    your living body for a thousand years. Do you accept my terms?"`
  },
  {
    text: `Freya hardened her heart and steeled her mind. This was why she was here. She could not let fear
    trap her. Freya nodded firmly, "I accept, oh Goddess of Worms and Serpents. What is your riddle?"
    Akrohnis’ eyes flared in delight and her thin lips curved into something almost resembling a smile.
    "Very well. Here is your riddle," she hissed.`
  },
  {
    text: `When bound, it chooses serf or king.
    When spilled, it foretells war or woe.
    When boiled, it fuels lusts and furies.
    When freed, it tumbles, falls, and fades away forever.
    When it runs, death will often follow.`,
    style: {
      fontStyle: "italic"
    }
  },
  {
    text: `Akrohnis stared at Freya, as still as a stone. "What say you, fleshling?"

    `
  },
  {
    text: `Freya thought hard on this. Her heart beat loudly in her chest. She could feel the blood pounding in
    her wrists. Freya closed her eyes, then it came to her.`
  },
  {
    text: `"Blood. The answer is blood. Blood chooses serf or king. When blood is spilled, it can foretell war or
    woe. Blood, when boiled, can fuel lust or anger, and when it runs, death may surely follow."`
  },
  {
    text: `Akrohnis slowly moved closer, the massive head mere inches from Freya’s. Freya could smell the
    rotten stench of a thousand bloody meals. It writhed around her like a living thing. "You are a clever
    girl, Freya. It seems you are not merely a warrior. Sweet, sweet blood is our answer." Akrohnis
    shifted her monstrous coils and slithered away, revealing a stone gateway previously hidden behind
    her.
   `
  },

  {
    text: `Beyond the gate, a portal led to a green land of fog and rain. Freya stepped through the gate and
    found herself in another realm entirely. This land was near the opposite of the previous. She was at the edge of a ruined village, in the midst
    of a fog-shrouded forest. The rain came down in solid sheets. Fog rolled along the ground. Bones of
    the dead filled the streets.`
  },
  {
    text: `Freya noticed that while some of the dead wore armor she recognized, most of them were clad in
    clothing and arms strange and unfamiliar to her. Freya stepped over them as she made her way
    further into the ruined village. At the town center, an enormous raven pecked at the remains of a
    fallen giant.`
  },
  {
    text: `Freya recognized the raven spirit at once. It was Nimogogg, Lord of the Dead. Freya approached him,
    and the massive bird immediately ceased pecking at the corpse and tilted its head towards Freya and
    cackled.`
  },
  {
    text: `"It seems Sister missed out on a very hearty meal indeed. Did you find her riddle challenging? I’m not
    much for riddles myself. Terrible at them, I am," Nimogogg said in a high-pitched, sardonic tone.`
  },
  {
    text: `Freya was silent. Nimogogg was known to her people as being a trickster, and while he was no great
    warrior, his words were often snares, binding the unknowing into terrible contracts and one-way
    deals often ending in their deaths… or worse. Silence, Freya thought, was the best course of action.`
  },
  {
    text: `Nimogogg stared at her for a moment, his big black eyes like endless voids, and then continued.
    "Well I suppose we should get on with it then, shall we?" the Raven God muttered before turning
    his head to the misty hill behind him.`
  },
  {
    text: `"Upon that hill yonder are four thrones. Each throne bears a crown. Each crown is a choice. Each
    choice has a consequence. Bring one crown back to me." Nimogogg cawed loudly as if laughing, then
    resumed his pecking at the corpse.
      `
  },
  {
    text: `Freya breathed deeply and made her way over the fallen warriors to the hill in the distance. The hill
    was so shrouded in mists that she could barely see her hand in front of her face. Freya’s boots
    crunched against the bones littered in the wet grass. Ghostly cries echoed all around her.
    
     `
  },
  {
    text: `Finally, Freya reached the top of the hill and the mists dissipated. Four thrones stood before her.
    The first throne was made of bones and mold. A crown of bone sat upon it.`
  },
  {
    text: `The second throne was made of iron. Engraved runes were carved into the metalwork. A crown of
    rune-etched iron sat upon it.`
  },
  {
    text: `The next throne was crafted of the most exquisite gold. A lavish golden crown encrusted with jewels
    sat upon it.
      `
  },
  {
    text: `The last throne was woven from lush and vibrant foliage, alive with greenery. A crown of emerald
    twisted wood with barbed thorns sat upon it.`
  },
  {
    text: `Suddenly, the shrill voice of Nimogogg rang out from all directions, "Each throne represents a way of
    rulership. Which seat will you choose? What kind of queen will Freya of the Thunder Tribe become?"`
  },
  {
    text: `Freya thought for a moment. She knew which throne called to her. Freya picked up the Iron Crown
    with engraved runes and placed it on her head.
      `
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/freya/Freya03.jpg`
  },
  {
    text: `"It is no surprise the daughter of Kiern chooses the path of War. Very well. If it is battle you wish, it is
    battle you will receive." The mists suddenly swirled around her, obscuring her vision and the wild
    cackle of Nimogogg rang out all around her. The massive Raven swooped above her, his wings
    causing a torrent of wind and mists to whip around her. Then just as suddenly as it began, the wind
    died down and the fog cleared.`
  },
  {
    text: `Freya found herself in a dark cave. The rock seemed to be made of a strange greenish-black mineral.
    Stalactites hung from the ceiling wet with condensation. Freya stepped forward then immediately
    froze.`
  },
  {
    text: `A looming silhouette shifted in the waiting murk. The hairs on the back of her neck raised and Freya
    leapt off to the side. A crackling bolt of blue lighting scorched the ground where Freya once stood.
    The form of a great dragon revealed itself, its eyes and maw an azure storm of fury and energy. Its
    tri-horned head and jagged spines were the color of ebony.`
  },
  {
    image:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/freya/Freya04.jpg"
  },
  {
    text: `Freya recognized the leviathan as Veltreik, first and king of the Spirit Dragons, dead thousands of
    years past. But here, in the world of dreams, he lived again.`
  },
  {
    text: `She knew what her test was, but how could she ever defeat such a monster? She did not have time
    to ponder as another torrent of lighting arced in her path. Freya dodged again, readied her blade and
    shield, and charged towards the beast.`
  },
  {
    text: `Veltreik shrieked as he rose up to his full height, filling the cavern. The massive span of his leathery
    wings spreading out endlessly. Freya took in this unreal sight. Shathoock! Another torrent of energy
    struck out from the dragon and Freya raised her shield to block. The shield instantly turned to glass
    and shattered in a thousand pieces. Freya avoided the next blast of energy by rolling behind a rock
    formation.
     `
  },
  {
    text: `Freya looked down at her blade. A relic of her ancestors. It was once her fathers. He proved himself
    here in this very realm. He passed the Three Labors and was deemed able and fit to rule the Thunder
    Tribe. She wanted him now. To guide her, to impart his wisdom. But her father was dead, and Freya
    was alone. Or was she?`
  },
  {
    text: `As she stared at her sword time seemed to stand still, a soft orange light started to emanate from the
    blade. Freya could feel the warmth from the light fill her to the very soul. A voice called out from the
    light and her eyes welled with tears.`
  },
  {
    text: `"Freya, know this: I am with you always. You are more than worthy to lead in my stead. Believe in
    yourself and the legacy I have given. You are a tempest, show the dragon your heart." With that, the
    glow from the blade gleamed and Freya, steeled by the words of her father, leapt from her hiding
    place and charged at the dragon, a warcry erupting from her lips. The dragon reached out with a claw
    to swipe at her, but she was too fast. She plunged the sword into Veltreik's belly. The dragon roared,
    and the orange light from the sword swelled to blinding intensity... then all went black.`
  },
  {
    image:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/freya/Freya5.jpg"
  },
  {
    text: `Freya awoke in the cold, dank, dark cave. She stood and looked around. Her tribe surrounded her,
    with calm, smiling and reverential eyes. They were silent as was the custom. The village elder came
    forward, bowed and place a gilded Iron Crown, strangely like the one from Nimogogg's realm, on her
    head. The Elder then sat a runed and ornate chest at her feet.`
  },
  {
    text: `Freya opened the chest to reveal the most majestic of weapons. Golden twin hammers, immaculately
    crafted and engraved with the rune of her tribe. Freya had passed the Three Labors, and now her
    reign began. She smiled, as she knew her father would be proud.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/freya/Freya06.jpg`
  }
];

export default content;
