const content = [
  {
    title: "Aradu and the Imp"
  },
  {
    text: `"Big day today, Ole Aradu," the dishevelled warlock said to himself as he put his extravagant - but
    cheaply-made - violet cloak over his shoulders with a dramatic flourish. "It’s taken fifty-three years and
    some change, but the wizarding world will finally recognize my unrelenting genius," he exclaimed as
    he extracted a stray piece of today’s brunch from his scraggly beard.`
  },
  {
    text: `"You dashing devil you! Those Xeridion Warlocks won’t know what hit them!" Aradu said as he
    winked at his own reflection. The Xeridion Warlocks were a not-so-secret society of
    shadow mages who congregated weekly to discuss the latest in villainous and contemptuous
    ongoings in the city of Toleen, show off their latest demonic summonings, and sample a rather
    overpriced selection of fine cheeses.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ezmo/boi_goin_out_tonight.jpg`
  },
  {
    text: `The practice of black magic and demonology was forbidden in Toleen, but the Warlocks managed to
    evade the local law enforcement and mageslayers by changing their meeting locations weekly and
    obscuring them through illusion magic.`
  },
  {
    text: `The leader of the club, the Grand Arcanist, was a mysterious wizard named Dor’Hazan. He was
rumored to be from ancient Faran Gol and former headmaster of the School of Storms before the
Deep Night threw the world in disarray. Of course, this was mere speculation as the Deep Night
was many centuries ago and all official records of the school were destroyed in the catastrophe.`
  },
  {
    text: `Dor’Hazan’s wizarding discipline of choice was demonology and he controlled what was most likely
    the most powerful demon in the civilized world, Mal’Kontentus. Mal’Kontentus was seven foot tall
    and wore a great black cloak, brandished massive leathery wings on his back and onyx-black horns
    on his enormous head. His eyes were wreathed in emerald fire and he was generally overall quite
    imposing and impressive.`
  },
  {
    text: `Mal’Kontentus greatly resented being under the thrall of such a lowly being - a human - and he took
    this resentment out daily on his own servant, a particularly clever trickster Imp named Ezmo. We’ll get
    back to Ezmo later.`
  },
  {
    text: `By all accounts, it was near impossible to track down where the Xeridion Warlocks met each week as
    they changed location, but it was even more difficult to join their black-and-purple-wearing ranks.
    Curiously, through some insane chance of luck, Aradu managed to finagle, bribe and blackmail his way
    into a one-time audition into the exclusive club. Tonight’s test was to be held in the dusty basement of
    the Alchemical Academy.`
  },
  {
    text: `Aradu rechecked his reflection as he reached for his bright green wizard's hat. The green clashed
    violently with the purple cloak, but Aradu’s fashion sense was almost as bad as his magical aptitude.
    He put it on carefully, avoiding the lump on his head from an incident earlier.`
  },
  {
    text: `There is one key thing to understand about Aradu. You see, he had a rather excessive measure of
    wizarding incompetence. In fact, if incompetence were a wizarding discipline in and of itself, Aradu
    would be its Archmage.`
  },
  {
    text: `To give Aradu credit; he certainly never stopped trying, no matter how… pitiful the results. Early
    this morning for instance, he performed a spell to retrieve his underwear from the top shelf in
    his drawer. The spell went "Underbottoms, it is you I command. Out of that shelf and into my
    hands!" Aradu caught the underbottoms on his face, and the shelf knocked him out for a solid two
    hours. So that explains the lump on his forehead. At the least he was thoroughly caught up on
    sleep.`
  },
  {
    text: `Out the door he went, with a hop and a merry skip and thanks to a random loose brick on the
    pavement, nearly a kiss with the dirt. Aradu was quite clumsy and had the hand-eye coordination
    of a newborn ostrich. He’d been that way all his life and merely shrugged it off as balancing to his
    immense genius. Mmmhmm. After a brisk walk, along with a few near death experiences, Aradu
    finally arrived at the Academy.`
  },
  {
    text: `Aradu waltzed right in like he owned the place, admiring the massive arches and crystal
    chandeliers hanging high from the ceiling. The College was unusually quiet, even if it was
    night time. "Well, that surely is peculiar," Aradu thought as he made his way through the main
    lobby and to the basement door.`
  },
  {
    text: `Aradu fumbled down the dusty, creaking steps and found himself in the grand presence
    of… no one at all. Moth-eaten couches, long discarded books and abandoned alchemical
    equipment was all that waited for him in the cobweb wallpapered cellar. He stood puzzled for a
    moment, then it hit him. "THIS must be the test. Of course, they wouldn’t hold the meeting out in the
    open. There must be some hidden contraption to open the secret door. Very clever I must say," Aradu
    admitted.`
  },
  {
    text: `Aradu scoured the room, searching for any detail that might seem out of place. To his eyes,
    nothing looked out of the ordinary. Then in a sudden jolt inspiration (or more accurately,
    frustration), Aradu began to turn over the couches and toss around the books and equipment,
    searching for any lever or button that would lead to a secret room. After a while, he collapsed, out of
    breath and ideas.`
  },
  {
    text: `In between huffs of heavy breathing, Aradu heard some strange noises coming from the back wall of
    the room. He crept closer and placed his ear against the wall, closed his eyes and concentrated on the
    sounds. He could hear a few people whispering but could not make out what they were saying. "They
    must be all talking about ME," Aradu thought to himself. "No doubt evaluating my stunning display
    of ambition and fortuitousness."`
  },
  {
    text: `Who could blame them really, who wouldn’t be excited to meet Aradu the Reserved, master
    of… well, nothing at the moment. But he was working on it. Don’t let your dreams remain dreams, kids.
    Realize them!`
  },
  {
    text: `Then a brilliant idea struck Aradu. Since the Xeridion Warlocks were obviously watching him by way
    of hidden magic behind the wall, he decided to show off his magical prowess. Magic was challenging
    in its own right for humans to perform, but he thought to surely impress them by combining two schools
    of magic. Transmutation and translocation. Combining a ghost form spell with a transportation spell.`
  },
  {
    text: `The idea was to give himself an incorporeal form, then use the transportation spell to blast himself
    through the wall and avoid colliding with anything on the other side. Aradu had heard of people
    sending themselves through walls without a ghost form, and ending up a permanent part of a
    grandfather clock. Aradu was far too clever to make that mistake.`
  },
  {
    text: `The nature of this spell called for magical energy to be concentrated on a crystalline substance. It has
    to do with the fact that a crystal can change density when it’s formed, but the details are not important.
    Aradu reached into his pockets and grabbed two pale-blue crystal balls each about the size of a large
    egg. He closed his eyes and channelled his energy as he chanted "Ghosts as pale as these crystal balls,
    send my body through these walls."`
  },
  {
    text: `As Aradu chanted, the whispers in the other room ceased utterly. Mists as pale as moonlight emerged
    from the crystals. It swirled down his legs and under his feet. A spinning disk made of clouds formed
    and started whirling into a miniature tornado, spinning faster and lifting Aradu up. "It’s working, it’s
    really working! My magical abilities know no bounds!", Aradu exclaimed excitedly.`
  },
  {
    text: `Aradu was about three feet off the ground as the tornado enveloped his body like hundreds of misty
    threads weaving a giant sock around him. With a sudden thrust forward, Aradu was propelled straight
    through the wall and into the secret chamber beyond. What Aradu saw next amazed and terrified him.
    He found himself in what must be Grand Arcanist Dor’Hazan’s private secret ritual chambers.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ezmo/boi_got_dem_anime_eyes.jpg`
  },
  {
    text: `The walls were lined with shelves upon shelves of dusty tomes. The room was dimly lit by an
    old chandelier made of the blackened bones of what once was a dragon, with silver candles
    emanating eerie green flames. Expensive alchemical kits and enchanting devices sat in corners.`
  },
  {
    text: `Wondrous and ancient magical artefacts sat on pedestals along the walls. A blood-red sword with a
    curved blade that wavered like ripples in a pond. It whispered in what Aradu assumed was the voices
    of the souls it had taken. A massive alabaster skull helmet with black smoke slowly pouring out of the
    eye sockets. A sparkling vermillion, jewel-encrusted wand, in which the gem at the tip changed color
    every other moment.`
  },
  {
    text: `But of all the wonders of the room, what caught Aradu’s fancy the most, was the ritual pentagram at
    the center of the chamber. Aradu himself wasn’t much of a demon summoner, in fact, he’d never even
    seen a demon before. But he knew enough of the basics to recognize a demonic circle when he saw
    one.`
  },
  {
    text: `Suddenly the most incredible idea struck him. What if he was to summon and control a demon to rival
    the one Dor’Hazan kept in his thrall? Surely the Xeridion Warlocks would be awed by his power and
    wit and let them into their secret club. Why, with a demon to rival Mal’Kontentus, he could take over
    the club himself. Grand Arcanist Aradu, Arch-Warlock, Lord of Demons. Oh, he liked the
    sound of that.`
  },
  {
    text: `Aradu scurried over to the demon circle and to his luck found a dusty tome there on a wooden
    pedestal. It was opened on the page of "Summoning Greater Chthonic Forces" and Aradu rubbed his
    hands together like a mischievous little imp. He had all of the necessary ingredients sans the most
    important one - "the blood of a Master Wizard."`
  },
  {
    text: `Unfortunately, this is where Aradu’s luck began to fizzle out. Aradu was, of course, convinced that he
    was indeed a "Master Wizard" and cut his palm in extreme confidence. He poured his blood into the
    onyx bowl and placed it at the center of the circle.`
  },
  {
    text: `Then, he began the incantation, "Hear me, being from the black beyond, heed the call of your
    master. I, Aradu, summon thee from the abyss, bound by shackles of alabaster!" The lines of
    the circle began to glow in the green light. The room slowly began to shake. A slow rumble
    started to steadily build with the appearance of swirling purple and black smoke forming in the
    center of the circle. The ritual was working!`
  },
  {
    text: `The rumbling started to subside, the green glow dimmed and the swirling smoke dissipated to reveal
    the form of… an imp? The creature wore a strange purple hood and where his face was supposed to
    be was nothing but a large mouth lined with pointed teeth and filled with fire. It had grey skin and
    clawed hands. But it was barely three feet tall! The creature, as you have probably guessed, was none
    other than Ezmo the Mischievous. Yet, this was hardly the Greater Demon Aradu had sought
    to summon.`
  },
  {
    text: `"Confound it!", Aradu shrieked. "This won’t do, this won’t do at all. There must have been a
    mistake in the ritual". Ezmo stretched out and yawned. "No mistake. You got what you paid for,
    buddy." Aradu slumped to the floor in frustration. He couldn’t do the ritual again, he was out of
    materials. But he might as well see what he’d summoned, he thought. Perhaps, he could get some use
    out of it. Aradu used his most official and authoritative voice.`
  },
  {
    text: `"Ahem, I am Aradu the Reserved. Demon, what are you, what are your abilities? What is your name?
    Speak quickly", Aradu commanded.`
  },
  {
    text: `Now there are many different rules that one must learn if he were to take the extreme risk of summoning
    a demon. But, none are more critical than the cardinal rule: Never under ANY circumstances do you give
    a demon your name. Names have power, to know one’s name is to control them. That was big mistake number one.`
  },
  {
    text: `Another rule that Aradu wasn’t aware of was that a bound demon must answer truthfully to any question he is
    asked as long as he is in the circle. Though he does not have to state truths if he is not asked questions directly.
    A shrewd demon could use this loophole to his advantage. And Ezmo was one of the shrewdest.`
  },
  {
    text: `"Well, I am, as you can clearly see, an Imp. My name is Ezmo. What do I do? Well, that is a rather
    general question is it not? The real question is you should be asking is, ‘What can Ezmo, do for you?’"
    Ezmo smiled his biggest smile, and being that his mouth took up all of his face, he was literally all
    smiles.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ezmo/nostrils_or_eyes_who_knows.jpg`
  },
  {
    text: `Aradu pondered this for a moment, then made another crucial mistake. He did not ask a question.
    "Well, you’re quite small and not very intimidating. Not sure what use you have at all, really."
    Ezmo’s mouth gleamed in brimming mischief. "Well then let me tell you. My current master is none
    other than Greater Demon Mal’Kontentus himself, and being that you are in Grand Arcanist 
    Dor’Hazan’s chamber, I’m sure you aware that Mal’Kontentus serves Dor’Hazan." Aradu nodded
    impatiently, "Yes, yes. I know all that. Get to the point, imp!"`
  },
  {
    text: `"Well we demons have a saying, ‘You scratch my wings, I scratch yours.’ Do something for me,
    and I’ll do something for you, Aradu." Aradu peered at Ezmo, he didn’t see any wings. Also,
    Aradu seemed to already have forgotten that Ezmo was HIS prisoner and would have to do as he was
    bid. Ezmo lifted his clawed hands, they were shackled by large runed gauntlets.`
  },
  {
    text: `"As long as I’m bound in these shackles, I can’t do much of anything. But, you see that book
    over there, behind you?" Aradu turned to see a sizeable strangely ornate tome behind him he did not
    notice before. The tome had a giant eye on the cover topped by three gold diadems and was inlaid with
    golden demonic runes.`
  },
  {
    text: `"That tome is the Lost Grimoire, said to house the most powerful spells known to man… or
    demonkind. If you want real power, that’s where you’ll get it, and I can get these shackles
    off," Ezmo said rather casually. Aradu’s eyes lit up. "The only problem is, only I know how to
    open it. If you hand it to me, I’ll open it for you, and its secrets and all the power within, will be
    yours."`
  },
  {
    text: `Ezmo gave another of those whole face smiles. Aradu narrowed his eyes. "Why don’t you just tell me
    how to open it, and I’ll do it myself," Aradu stated suspiciously, quite proud of his erudite cleverness.
    "Well, because I have to touch it to open it." Ezmo retorted quite matter of factly. "Oh," Aradu said,
    deflated. "Very well then." Aradu reached over grabbed the book and made his final mistake. He
    handed Ezmo the Grimoire.`
  },
  {
    text: `"Why thank you, Aradu. It’s been a pleasure," Ezmo said merrily. He then spoke in the dark
    tongue of Demonic, and as soon as the words had left his lips, several things happened at once:`
  },
  {
    text: `The book flew open in Ezmo’s hand, Aradu started to quiver he was lifted from the ground by some
    invisible force, the chain that held Ezmo’s shackles shattered, and the demon circle evaporated. The
    last thing Aradu saw as his body was sucked into the book was the invitation to the Xeridion Warlock
    Club fly out of his inner pocket and land on the floor. He’d read the date wrong.`
  },
  {
    text: `The audition was tomorrow.`,
    style: {
      textAlign: "center"
    }
  }
];

export default content;
