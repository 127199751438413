import Freya from "./stories/freya";
import RuhKaan from "./stories/ruhkaan";
import Raigon from "./stories/raigon";
import Ezmo from "./stories/ezmo";
import Ashka from "./stories/ashka";
import Blossom from "./stories/blossom";
import Croak from "./stories/croak";
import Bakko from "./stories/bakko";
import Pestilus from "./stories/pestilus";
import Jumong from "./stories/jumong";
import Iva from "./stories/iva";
import Lucie from "./stories/lucie";
import Alysia from "./stories/alysia";
import Shifu from "./stories/shifu";

import TalonIsland from "./stories/talonIsland";

import { convertStories } from "../util/text";

const stories = [
  {
    content: RuhKaan,
    champion: "ruh_kaan",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ruhkaan/ruhPreview.jpg",
    cardImage: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ruhkaan/BookOfLoreRuhKaan.jpg`,
    date: "2018-10-24",
    status: "released",
    _id: 1
  },
  {
    content: Raigon,
    champion: "raigon",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/raigon/raigon-preview.jpg",
    cardImage: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/raigon/raigon_ft_boi.png`,
    date: "2018-10-31",
    status: "released",
    _id: 2
  },
  {
    content: Ezmo,
    champion: "ezmo",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ezmo/ezmo_preview.png",
    cardImage: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ezmo/boi_goin_out_tonight.jpg`,
    date: "2018-11-7",
    status: "released",
    _id: 3
  },
  {
    content: Freya,
    champion: "freya",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/freya/speak_to_me_harry.png",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/freya/pretty_windy.jpg",
    date: "2018-11-14",
    status: "released",
    _id: 4
  },
  {
    content: Ashka,
    champion: "ashka",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ashka/preview.png",
    cardImage: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/unknown/imagine_waking_up_to_this_boi.jpg`,
    date: "2018-11-21",
    status: "released",
    _id: 5
  },
  {
    content: Blossom,
    champion: "blossom",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/blossom/preview.png",
    cardImage: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/blossom/Blossom02.jpg`,
    date: "2018-11-28",
    status: "released",
    _id: 6
  },
  // {
  //   content: [],
  //   champion: "new_phone_who_dis",
  //   pinPreviewImage:
  //     "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/unknown/decode-me.png",
  //   status: "hidden",
  //   _id: 999
  // },
  {
    content: Croak,
    champion: "croak",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/croak/preview.png",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/unknown/take_a_bath.jpg",
    date: "2018-12-5",
    status: "released",
    _id: 7
  },
  {
    content: Bakko,
    champion: "bakko",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/bakko/preview.png",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/bakko/bakko4.jpg",
    date: "2018-12-12",
    status: "released",
    _id: 8
  },
  {
    content: Pestilus,
    champion: "pestilus",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/pestilus/preview.png",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/pestilus/how_to_make_a_boi.jpg",
    date: "2018-12-19",
    status: "released",
    _id: 9
  },

  {
    content: Jumong,
    champion: "jumong",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/jumong/preview.png",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/jumong/strong_independant_boi.jpg",
    date: "2019-01-09",
    status: "released",
    _id: 10
  },

  {
    content: Iva,
    champion: "iva",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/iva/preview.png",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/unknown/THESE_WOUNNNNDS_THEYY_WILLL_NOT_HEAAAALLLLLLLLL.jpg",
    date: "2019-01-16",
    status: "released",
    _id: 11
  },

  {
    content: Lucie,
    champion: "lucie",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/lucie/preview.png",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/lucie/asdanfkjnweljbglsdfbgsel.jpg",
    date: "2019-01-30",
    status: "released",
    _id: 12
  },
  {
    content: TalonIsland,
    champion: "Talon Island",
    cardImage: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/talonIsland/TalonIsland04_tall.jpg`,
    date: "2019-02-20",
    status: "released", //"released",
    _id: 13
  },
  {
    content: Alysia,
    champion: "alysia",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/alysia/preview.png",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/alysia/whatNiceHandsYouHave.jpg",
    date: "2019-03-06",
    status: "released",
    _id: 14
  },
  {
    content: Shifu,
    champion: "shifu",
    pinPreviewImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/shifu/preview.png",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/shifu/whatBigEyesYouHave.jpg",
    date: "2019-04-03",
    status: "featured",
    _id: 15
  },
  {
    content: [],
    champion: "???",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/unknown/Book_big.jpg",
    date: "2022-02-27",
    status: "preview", //"featured", //"released",
    _id: 999
  },
  {
    content: [],
    champion: "???",
    cardImage:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/unknown/Book_big.jpg",
    date: "2022-03-06",
    status: "preview", //"featured", //"released",
    _id: 1000
  }

  // {
  //   content: [], //Alysia,
  //   champion: "alysia",
  //   pinPreviewImage:
  //     "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/alysia/preview.png",
  //   cardImage:
  //     "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/alysia/alysia1.jpg",
  //   date: "2019-01-09",
  //   status: "preview", //"featured", //"released",
  //   _id: 12
  // }
];

export default convertStories(stories);
