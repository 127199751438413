import React, { Component } from "react";

import "./css/PityScreen.css";

export default class PityScreen extends Component {
  render() {
    return (
      <div className="pityContainer">
        <div className="transparentOverlay">
          <div className="pity-overlay">
            <div className="pity-content">
              <div>
                <h1>Sorry!</h1>
                <h2>We dont support your browser.</h2>
                {/* <p>{this.props.browserName}</p> */}
              </div>

              <div>
                <h3>We currently support:</h3>
                {this.props.supportedBrowsers.map((name, index) => (
                  <div key={index}>{name}</div>
                ))}
              </div>
              <div className="fancyButton" onClick={this.props.ignoreWarning}>
                Proceed Anyway
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
