const content = [
  {
    title: "The Hunter and the Beast Prince"
  },
  {
    text: `Once upon a time, long, long ago, there was a nameless Hunter. The Hunter was made of a

    mold few others were. You see, this Hunter knew only of forests, of woods, of abodes
    
    without walls, and meals without companionship. Yes, this Hunter was a man, but he did not
    
    wish it so.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/jumong/strong_independant_boi.jpg`
  },
  {
    text: `The Hunter was abandoned by humans before he could remember, and though he knew the

    tongue of Man, very seldom did he use it. The Hunter preferred the sounds of the wind blowing
    
    through trees, the rain falling on rocks and the earth crunching beneath his feet. But above all
    
    else, he loved the thrill of the Hunt.`
  },
  {
    text: `One night the Hunter found himself in a strange wood. The unearthly forest let no light from the

    sun enter, and the trees themselves were pale and ghastly, with long spindly limbs and twisted,
    
    contorted trunks.`
  },
  {
    text: `
    The Hunter was on the trail of a rare creature, unlike any he had hunted before. A silver wolf,
    
    with a mane woven from moonlight and eyes like fiery rubies. The wolf was cunning, agile and
    
    fleet of paw, yet the Hunter was good at only one thing, but at this, he was the very best.`
  },
  {
    text: `The silver wolf darted into view, and the Hunter stood as still as a statue, as silent as a spider.

    It sniffed the air, alert to danger. The Hunter drew his bow, slow and steadily, nocked an arrow
    
    and drew, then loosed all in one blinding motion. Thwap! The arrow whistled in the night air for
    
    less than a moment, and then the Silver Wolf fell, crashing through the bramble.`
  },
  {
    text: `Upon arriving at the wolf's body, The Hunter saw something quite strange indeed. The Wolf was

    no wolf, but something else entirely. The creature had the face of a wolf but the stature of man.
    
    Cover in silver and black fur, around its neck a golden medallion with emerald gem and on its
    
    head a crown of twisted black wood embedded in the beast’s brow. Blood flowed freely from
    
    where the Hunter’s arrow pierced its shoulder. The creature spoke. Its voice was a deep low
    
    rumble.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/jumong/tis_but_a_flesh_wound.jpg`
  },
  {
    text: `"You’ve trespassed into my domain. You’ve hunted my subjects. And now you hunt the Beast

    Prince himself. I know I am your greatest capture yet, but there is a still greater prey. Spare
    
    me, and I will lead you to a trophy beyond your dreams. I will award you also with a gift from my
    
    sacred treasury. What say you, Hunter?"`
  },
  {
    text: `The Hunter thought on this. While the Beast Prince was undoubtedly his most magnificent

    capture, he could not resist the notion of prey even greater. A challenge worthy of his talents.
    
    So the Hunter agreed. He carefully removed the arrow from the Beast Prince’s shoulder. And the Beast
    
    Prince led him to his castle, deep in the heart of the wood.`
  },
  {
    text: `Once the Hunter and the Beast Prince arrived at the Castle, the Hunter was surprised to find it

    empty. The Castle was a brooding macabre spectacle, with oppressive black towers and red glass
    
    windows, and yet in the immense silence, it felt more like a mausoleum. There was a
    
    melancholy that filled the castle, where subjects once lived. The Hunter asked, "Where are all
    
    your subjects?" The Beast Prince grunted and did not answer.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/jumong/Jumong03.jpg`
  },
  {
    text: `The Beast Prince led him down a staircase into a dark, dank room. The Hunter reached for his

bow, sure he was walking into a trap. The Beast Prince lit a torch and revealed the place before

him. It was quite the treasure trove, holding all manner of gold, trinkets, jewels the size of hearts,

and pearls the size of eggs. Among the treasure were artifacts and strange items of ancient and

unknown variety.

`
  },
  {
    text: `"For your mercy, you may choose among these treasures. But you may only select one and

    once chosen, you can never go back." The Hunter nodded and looked among the treasures.
    
    Among all the arcane relics and lavish riches two items curiously stood out. One was a tattered
    
    blue cloak, a pale green glow flowed through the fabric like gentle waves of light, ever moving and
    
    shifting. It promised great power, yet there was something foreboding about it. The other was a
    
    ragged fur mantle made from the hide of a great beast, complete with sharp fangs and glowing, ice blue eyes. "What are these?" The Hunter asked.`
  },
  {
    text: `"The first is the Cloak of Thorns. It is said to give the wearer the strength of the earth and the

    power of immortality. But it exacts a high price. The other is the Mantle of Jumong, it imbues its
    
    wearer with the heightened senses of a beast, allows him able to blend in with his surroundings
    
    and prowl like the greatest of predators. But again, at a great cost."`
  },
  {
    text: `The Beast Prince held his breath as the Hunter reached for the beast hide mantle, for he knew

    the Hunter could not resist becoming more like the beast he saw himself as. The Hunter
    
    hesitated, then asked, "Once chosen, I cannot go back?" The Beast Prince replied almost in a
    
    whisper, "Never."`
  },
  {
    text: `The Hunter took the Mantle of Jumong and placed it upon his shoulders. Immediately the

    Hunter could feel his limbs strengthen, and his senses came alive, as if an obscuring cloud
    
    dispersed from them. He could smell smells he’d never smelled before, his eye saw colors in a
    
    new robust glory, he could even taste the air. Yet, as the shackles fell from the Hunter senses,
    
    he felt a new shackle grip his heart in coldest iron.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/jumong/boi_got_new_dreads.jpg`
  },
  {
    text: `"Now, Beast Prince," the Hunter snarled behind newly sharpened teeth, "Where is the prey you

    promised?" The Beast Prince, nodded, "Yes, yes, I did promise, didn’t I. Come with me."
    
    The Beast Prince led the Hunter back out of the Castle to the front gates. "Many many years
    
    from now, there will emerge a creature you’ve never seen the like of before. It will be an
    
    abomination, yet you will recognize it. Then you will know it is the creature you have been
    
    promised to hunt."`
  },
  {
    text: `The gates slammed down between the Hunter and the Beast Prince. The Hunter growled

    furiously for he felt he had been tricked by the Beast Prince. He pounded on the black gate, yet
    
    there was no reply. Though the Hunter thought he had been fooled, the Mantle of Jumong was
    
    an excellent prize in its own right, and there would surely be more prey to hunt. So, the Hunter
    
    made his way from the Castle into the woods and soon found a new hunt to pursue.`
  }
];

export default content;
