import React, { Component } from "react";
import Draggable from "react-draggable"; // Both at the same time
import Pin from "./Pin";
import DebugOverlay from "./DebugOverlay";
import MousePositionFollower from "./MousePositionFollower";
import { debug } from "../config";
import playSound from "../../_shared/data/sounds";

import "./css/Map.css";

export default class Map extends Component {
  dragLimit = 10;
  state = {
    mapBoundaries: {},
    mapHeight: undefined,
    mapWidth: undefined,
    mapImageWidth: 4401,
    mapImageHeight: 3372,
    coords: {
      mouseX: 0,
      mouseY: 0,
      actualX: 0,
      actualY: 0
    },
    controlledPosition: {
      x: -1000,
      y: -1200
    },
    showPearl: false,
    pearlStatus: [
      { letter: "p", pressed: false, sound: "pearl1" },
      { letter: "e", pressed: false, sound: "pearl2" },
      { letter: "a", pressed: false, sound: "pearl3" },
      { letter: "r", pressed: false, sound: "pearl4" },
      { letter: "l", pressed: false, sound: "pearl5" }
    ],
    justPressedKey: false
  };

  resetPearl() {
    this.setState({
      pearlStatus: [
        { letter: "p", pressed: false, sound: "pearl1" },
        { letter: "e", pressed: false, sound: "pearl2" },
        { letter: "a", pressed: false, sound: "pearl3" },
        { letter: "r", pressed: false, sound: "pearl4" },
        { letter: "l", pressed: false, sound: "pearl5" }
      ]
    });
  }

  keyInputs = ({ key }) => {
    const pearlStatus = [...this.state.pearlStatus];

    for (let i = 0; i < pearlStatus.length; i++) {
      if (pearlStatus[i].pressed) {
        continue;
      }
      if (pearlStatus[i].letter !== key) {
        this.resetPearl();
        break;
      }
      if (pearlStatus[i].letter === key && !pearlStatus[i].pressed) {
        if (this.state.justPressedKey) {
          break;
        }
        pearlStatus[i].pressed = true;
        if (!this.props.isMuted) playSound(pearlStatus[i].sound);
        //last one
        if (i === pearlStatus.length - 1) {
          this.setState({
            showPearl: true
          });
        }
        this.setState(
          {
            pearlStatus,
            justPressedKey: true
          },
          () => setTimeout(() => this.setState({ justPressedKey: false }), 100)
        );
        break;
      }
    }
  };

  onControlledDrag = (e, { x, y }) => {
    this.setState({ controlledPosition: { x, y } });
  };

  onDragStart = () => {
    this.setState({ oldPosition: this.state.controlledPosition });
  };

  onDragStop = () => {
    if (
      !(
        this.state.controlledPosition.x >
          this.state.oldPosition.x + this.dragLimit ||
        this.state.controlledPosition.y >
          this.state.oldPosition.y + this.dragLimit ||
        this.state.controlledPosition.x <
          this.state.oldPosition.x - this.dragLimit ||
        this.state.controlledPosition.y <
          this.state.oldPosition.y - this.dragLimit
      )
    ) {
      this.toggleModal(-1)("smallModal");
    }
  };

  updateMousePosition = tmp => e => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const actualX = e.pageX;
    const actualY = e.pageY;
    clearTimeout(tmp);
    tmp = setTimeout(() => {
      this.setState({
        coords: {
          mouseX: Math.ceil(x),
          mouseY: Math.ceil(y),
          actualX,
          actualY
        }
      });
    }, 10);
  };

  // also closes all other open modals
  toggleModal = index => type => {
    const pins = [...this.props.pins];
    let resetPins = pins.map((pin, i) => {
      if (index === i) {
        pin[type] = !pin[type];
      } else {
        pin[type] = false;
      }
      return pin;
    });
    this.props.updatePins(resetPins);
    return true;
  };

  handleResize = () => {
    this.setState({
      mapHeight: window.innerHeight,
      mapWidth: window.innerWidth - this.props.sideBarWidth,
      mapBoundaries: {
        top: window.innerHeight - this.state.mapImageHeight,
        left:
          window.innerWidth - this.state.mapImageWidth - this.props.sideBarWidth
      }
    });
  };

  changeControlledPosition = (xdiff, ydiff) => {
    let { x, y } = this.state.controlledPosition;
    x = x + xdiff;
    y = y + ydiff;
    if (x > 0) {
      return { x: 0, y: this.state.controlledPosition.y };
    }
    if (x < this.state.mapBoundaries.left) {
      return {
        x: this.state.mapBoundaries.left,
        y: this.state.controlledPosition.y
      };
    }
    if (y > 0) {
      return { x: this.state.controlledPosition.x, y: 0 };
    }
    if (y < this.state.mapBoundaries.top) {
      return {
        x: this.state.controlledPosition.x,
        y: this.state.mapBoundaries.top
      };
    }
    return {
      x,
      y
    };
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("keydown", this.keyInputs);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("keydown", this.keyInputs);
  }

  //champion pins
  championContent = pin => {
    const championStory = this.props.stories.find(
      story => story.champion === pin.champion
    );
    return {
      name: pin.champion,
      title:
        championStory && championStory.content.length > 0
          ? championStory.content.find(c => c.title).title
          : null,
      image: championStory ? championStory.pinPreviewImage : null,
      storyId: championStory ? championStory._id : null,
      pinStyle: pin.pinStyle
    };
  };

  //location pins
  championsContent = pin => {
    return pin.champions.map(name => {
      let championStory = this.props.stories.find(
        story => story.champion === name
      );
      if (championStory && championStory.content.length === 0)
        championStory = false;
      return {
        name,
        storyId: championStory ? championStory._id : null
      };
    });
  };

  render() {
    const { controlledPosition } = this.state;

    const mapCSS = {
      minWidth: this.state.mapWidth,
      minHeight: this.state.mapHeight,
      width: this.state.mapImageWidth,
      height: this.state.mapImageHeight
    };

    return (
      <div className="map-app ">
        <div className="content">
          <div
            className="mapContainer"
            style={{ width: this.state.mapWidth, height: this.state.mapHeight }}
          >
            <Draggable
              position={controlledPosition}
              onStart={this.onDragStart}
              onStop={this.onDragStop}
              onDrag={this.onControlledDrag}
              handle=".handle"
              grid={[1, 1]}
              cancel=".mapInteractable"
              bounds={{
                top: this.state.mapBoundaries.top,
                left: this.state.mapBoundaries.left,
                bottom: 0,
                right: 0
              }}
            >
              <div className="handle map" style={mapCSS}>
                {this.state.showPearl ? (
                  <div className="pearl-overlay" />
                ) : null}

                {this.props.pins.map((pin, index) => {
                  const championContent = pin.champion
                    ? this.championContent(pin)
                    : null;
                  const championsContent = pin.champions
                    ? this.championsContent(pin)
                    : null;

                  return (
                    <Pin
                      sounds={this.props.sounds}
                      x={pin.x}
                      y={pin.y}
                      key={index}
                      location={pin.location}
                      arena={pin.arena}
                      championContent={championContent}
                      champions={championsContent}
                      showSmallModal={pin.smallModal}
                      toggleModal={
                        !championsContent &&
                        !pin.arena &&
                        !championContent.storyId
                          ? () => null
                          : this.toggleModal(index)
                      }
                      openStory={this.props.loadStory}
                      isMuted={this.props.isMuted}
                    />
                  );
                })}
                <DebugOverlay
                  active={debug}
                  updateMousePosition={this.updateMousePosition}
                />
              </div>
            </Draggable>
          </div>
          <MousePositionFollower active={debug} coords={this.state.coords} />
        </div>
      </div>
    );
  }
}
