const content = [
  {
    title: "A Winter’s Wish"
  },
  {
    text: `Once upon a time in a land of endless winter and eternal night there dwelt a black, brooding castle.`
  },
  {
    text: `This land was not always in neverending frost, but was cursed. The residents of the castle were not a gentle, loving folk. They did not care to frolic in golden sunbeams or stroll in green-leafed gardens. No, theirs was the frigid bite of winter and a gray sky that knew no light. They cursed this land and in many ways the land reflected their hearts - cruel, frozen, and without pity.`
  },
  {
    linebreak: true
  },
  {
    text: `In this cold heart of a castle there lived a princess named Alysia`
  },
  {
    text: `In a castle full of her family and people, Alysia felt utterly alone. She was not like her people in many ways. Where her family was pale-skinned and dark-haired, her hair was a lovely pink and her skin a sandy tan. Her people desired nothing more than to dominate others, and Alysia’s only desire to create life through her ice sculptures.`
  },
  {
    text: `Alysia would spend many a day creating an entire world in her vast room. A world of frozen beings.`
  },
  {
    text: `The statues she sculpted were beings she had read about in books: fauns, giants, centaurs, and elves from strange, far-off lands. She was constantly in search of the perfect pose. A special motif. Her books filled her head with wondrous ideas - but something was missing. Her ice statues were very well crafted... but they didn't seem alive.`
  },
  {
    text: `Her mother and father, the King and Queen, had no use for flights of fancy, of art or poetry. They spent their days planning ways to conquer and enslave other kingdoms, and the slaves that were brought back to the castle were treated worse than beasts.`
  },
  {
    text: `And while Alysia surely felt awful for the poor creatures, she couldn't help but think that if she could only get one to herself... maybe it would inspire her art. That pain, that burning fire for freedom. Wasn't that the essence of life itself?`
  },
  {
    linebreak: true
  },
  {
    text: `It was Alysia's birthday. A magnificent gown was sewn for her to wear. It was beautiful in its macabre glory. The dress was blue and as dark as a lightless lake, little gleaming red ice crystals were woven into the fabric, which gave the look of bloody stars in a night sky.`
  },
  {
    text: `Alysia was summoned to the banquet hall. A great feast was held in the princesses' honor. The hall was full of her people: tall, pale, and dark-haired. Angular and severe of visage. And although they spoke among themselves, there was no warmth or mirth.`
  },
  {
    text: `Great mountains of food were served - none that were particularly delightful to Alysia. Black frosted cakes, bitter dark ale, and bloody cuts of meat. But the celebration felt hollow for Alysia. She was bored almost to tears. It was dead. Lifeless and dreary. Alysia craved to return to her work. To continue her search for the perfect motif.`
  },
  {
    text: `Suddenly there was a great clamor and a silence fell over the banquet hall. One of the  servants, a red, feathery bird-like creature, had accidentally fallen and spilled drinks on the floor. Alysia watched.`
  },
  {
    text: `"Finally, some excitement," she thought.`
  },
  {
    text: `The Queen rose up to her full height. She wore a long blood-red gown. And if her deathly pale skin, yellow eyes, and raven-black hair weren't intimidating enough, her uncannily beautiful features were twisted in an expression beyond rage.`
  },
  {
    text: `“Creature! Slave! Come before me this instant!" The Queen shrieked in a voice that shook the banquet hall. The red-feathered creature made the long trek from the end of  the banquet hall to the throne, being kicked and spat upon all the way there.`
  },
  {
    text: `The creature got on his knees and bowed before the looming black-crystal thrones of the King and Queen. "P-please your Highnesses. Forgive your humble servant! I didn't m-mean to... someone tripped me!"`
  },
  {
    text: `"Silence your pitiful stammering, wretch! I did not give you permission to speak! It seems we have a particularly insolent slave in our midst don't we, husband?"`
  },
  {
    text: `The Queen smiled a wicked smile. Her husband, the King, merely nodded, as bleak and stony as a nod could be. "Indeed we do, Queen-wife."`
  },
  {
    text: `"And we of Karvok do not take kindly to insolence." The Queen raised her hand in the air, and from nothing at all a black ice whip materialized in her palm. The little red-feathered creature shivered in fear so violently he began to molt. The Queen slowly walked over to the bird; the whip raised to strike.`
  },
  {
    text: `Up to this point Alysia had watched all of this happen, enraptured in the drama. But seeing the poor creature in such a state gave Alysia an idea.`
  },
  {
    text: `As the black whip came screaming down on the beast an ice barrier formed around it, blocking the strike.`
  },
  {
    text: `A collective gasp rang out from all of the banquet guests. Alysia's hand was raised as ice poured from her fingertips. She'd shielded the poor creature and pleaded in a dramatic princess tone. "No! Please. Don't hurt him, Mother. Can't you see he's sorry? You don't have hurt him!"`
  },
  {
    text: `The Queen turned to Alysia with a look that could turn blood to ice. "How DARE you defy your Mother. Your Queen! Up to your room this very instant! And no supper!" Alysia stood and held back a wry smile. Her plan had worked.`
  },
  {
    text: `Alysia turned around, feigned defeat and went up to her room. But she smiled to herself. She only defied her mother so she could be sent away. Her parents may be cruel, but they were also very predictable.`
  },
  {
    text: `Alysia looked over her creations. She sighed in real defeat. She was dry of inspiration. Her cold world offered her no muse.`
  },
  {
    text: `As Alysia looked up into the sky, a strange light caught her eye. It was golden, the color of sand. She could not remember ever seeing any stars in the sky, let alone one that shone so intensely.`
  },
  {
    text: `She'd read in books that people would sometimes wish on a star and their wishes came true. So without much to lose, she wished.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/alysia/whatNiceHandsYouHave.jpg `
  },
  {
    text: `"If there is truly someone out there who cares...here is my wish. I wish to leave this castle, this family… this life, forever. To go where I can find inspiration, find life and excitement... be an artist! To find that perfect motif."`
  },
  {
    text: `The golden star twinkled as if in reply, then disappeared.`
  },
  {
    text: `Alysia was just about to go to bed convinced her wishing was a childlike fancy when sparkling sands started swirling at her feet.`
  },
  {
    text: `She took a few steps back as shimmering strands of sand started appearing from nowhere and massed together to form a strange creature. Alysia looked on, mouth agape as the creature brushed some of the dust off of his cream-colored fur.`
  },
  {
    text: `"It seems, somehow beyond all measures of the strands of fate and time, you've called me here, now, in this very moment."`
  },
  {
    text: `Alysia looked on, speechless. Nothing good or extraordinary ever happened to her, but here she was, and here… it was. What was it?`
  },
  {
    text: `"Who... who are you? How did you get here?"`
  },
  {
    text: `“Well, it seems you called me here, young one. And who I am? Well, at this moment in time, I believe I am called... Oldur. Pleased to meet you."`
  },
  {
    text: `Oldur held out his furry hand and Alysia shook it.`
  },
  {
    text: `"Pleased to meet you as well, Oldur. I am Alysia. Do you think you can help me leave this  place? I want to find life and inspiration to my sculptures."`
  },
  {
    text: `"Of course. But my arrival here is less to do with your wishes, and more to do in the role you will play in the future. Time is a vast ocean, and somehow, your message in a bottle washed upon my thoughts. I do not question, merely serve."`
  },
  {
    text: `Alysia looked on puzzled, she didn't quite yet know what to make of this strange creature.    "Look now, and see what lies in store."`
  },
  {
    text: `Oldur waved his paws, and a sparkling sand-framed vision appeared. Inside were arenas of battling champions, full of action, where life and death ebbed and flowed. Hearts burned with  fury, passion, and heroism. Alysia's eyes widen in amazement. This was the place she'd dreamed of.`
  },
  {
    text: `“Yes, please! I want to go to this place. I'll surely find my motif there!"   `
  },
  {
    text: `Oldur nodded. Oldur then raised his hourglass in the air and turned it upside down.`
  },
  {
    text: `"When the final grains of sand fall you will be free, young Princess. Go now. Downstairs to the hall.  See what I have done, and flee while time is on your side."`
  },
  {
    text: `And with that, Oldur collapsed into a man-sized mound of sand, and the sands swirled once more, and then they disappeared entirely.`
  },
  {
    text: `Cautiously Alysia crept back down into the hall. Silence is all that greeted her. All the banquet guests had entirely stopped moving as if frozen in time. Her parents were frozen as well, living statues with proud, haughty looks on their faces. She could not help but admire them in their still beauty. Somehow they felt more alive this way.`
  },
  {
    text: `Alysia could not believe it, she was finally free. She walked out of the castle walls right out the front gate. She did not look back.`
  },
  {
    text: `Alysia took in her first breath of freedom as she walked into a new life. Now she could spread her art of ice sculpting for the entire world to see. She would journey to these fabled arenas and find the perfect motif.`
  },
  {
    text: `And for the first time, in a very long time...  `
  },
  {
    text: `Alysia smiled.`
  }
];

export default content;
