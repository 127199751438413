const content = [
  {
    title: "Croaked"
  },
  {
    text: `The Witch’s hut was terribly uninspired. Festering knee-length weeds crawling with all manner of
    bloodsucking insects surrounded the dreary shack. The thatched roof was damp and mold-ridden. A
    distinct smell of sulfur, burnt wood, and patchouli emanated from the house like the stench off of a
    dead rat. Bones of animals bound by twine hung in front of the cobwebbed windows and twinkled
    like windchimes.`
  },
  {
    text: `Like I said, uninspired.`
  },
  {
    text: `The job wasn’t anything unusual and, for one of my skill-set, should have been as easy as finding
    leeches in swamp water. But there’s an old adage among the Ranid Assassins: "The next kill is only
    easy until it’s your last." Now, this was by no means my last kill… but let’s just say, things got rather
    complicated.`
  },
  {
    text: `Get in, kill the Witch, steal the dagger, get paid. A simple process. And I like simple. Only this time, it
    wasn’t simple. It wasn’t simple at all. See, usually I would blend in with the shadows and wait for the
    witch to leave her shack to get firewood, tend to her garden (witches love gardening by the way), or
    go off to cast some spell on some unfortunate maiden or whatever it is witches do. But this particular
    hag just wouldn’t leave.`
  },
  {
    text: `I staked out the hut for two days and she just carried on inside without any intention of ever leaving
    the damned place. So I had to change the plan… and I hate changing the plan. That’s usually when
    things go wrong. Oh, and things went so horribly wrong.`
  },
  {
    text: `I waited until I heard the old hag snoring (she sounded like an asthmatic bear) and then slowly, ever
    so slowly, I opened the front door. It creaked slightly, but the witch stayed snoring. Good sign. I crept
    in and drew my blade. Ranid standard issue, nothing fancy. I looked up to see two golden, glowing
    eyes looking down at me. It was a large, ragged black cat. Cliché, I know. And I hate cats.
    `
  },
  {
    text: `The cat tilted its head curiously at me, so I stuck out my tongue at it, fervently displaying my hatred
    for the haunty balls of fluff. I instantly regretted the whole tongue thing, because the cat immediately
    unleashed the most ungodly yowl I’ve ever heard. Some sort of supernatural, witchy alarm spell.
    `
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/croak/boi_got_nice_shadow.jpg`
  },
  {
    text: `The Witch sprang up, wand in hand, with surprising agility I must say for an old bag. Her haggard,
    yellow eyes looked down at the blade in my hand, and likely guessed what I was up to. She hissed
    at me and spoke some mumbled incantation under her breath.`
  },
  {
    text: `A yellow lightning bolt sizzled from her wand and struck where I once stood. I looked down from
    where I stuck to the ceiling (a simple Ranid cantrip for weight manipulation). "Look, ya old crone, you
    can make this easy on yourself. Why die tired? It’ll be over nice and quick." I spat at her.
    `
  },
  {
    text: `I leapt down for a quick blade slash to her throat, and she moved away with supernatural speed.
    "Guess you’re going to play hard to get, eh?", I said as I drew on the secret techniques taught by the
    Ranid and melded away into the shadows, disappearing from her sight. The witch shrieked, "Cursed
    Ranid black arts! An abomination of good spellcraft it is!" She ran for the door. She was fast, but not
    faster than ole Crevlos. I burst from the shadows and buried my blade in her back. The edge
    scraped against her spine as I twisted it for good measure.
    `
  },
  {
    text: `"There there, you wretched creature, it’s all said and done. Hush now. Into the dark you go."`
  },
  {
    text: `The Witch slumped to the floor, and let out one of those dramatic death moans. Heard one, and
    you’ve heard them all. Now with the witch out of the way, I needed to find that dagger. My eyes
    surveyed the room. There it was on the top of the mantle above the fireplace. Not very
    inconspicuous.
    `
  },
  {
    text: `It was a humble design: ebonwood hilt, wavy, rusted blade, and a pale green gemstone embedded
    in the quillon. Venomancer Rezakor would be pleased and I’d make a sweet bit of coin. I snatched
    up the dagger and stepped over the dead witch.
    `
  },
  {
    text: `Suddenly, the witch sprung up and grabbed my arm. Her eyes blazed in a green malevolent fire. A
    scalding flash of pain seared up my arm and through my chest. "A curse! A curse of Maggorot upon
    you!", the witch wailed.
    `
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/croak/gross.jpg`
  },
  {
    text: `I snatched my arm away and flung the dagger into her heart. She instantly burst into ash, showering
    me in a cloud of the dull grey debris. I coughed, grabbed the ebonwood dagger and high-tailed it out
    of there. My arm felt like it was on fire and as I looked down at it, I could see black-green veins
    sprouting where the Witch grabbed me. This… did not look good.
    `
  },
  {
    text: `By the time I reached Rezakor, the flesh rot had nearly spread over my entire right arm, covering it in
    rotted, black-green scales. I could no longer move or even feel my arm. It felt stiff and smelled like
    boiled death.
    `
  },
  {
    text: `The Venomancer dared chuckle as I approached the clearing in the swamp where he sat and
    meditated on a tree stump. "Rough going of it, eh, Crevlos? I could smell the Maggorot on you a mile
    off. Ole Mrezda put up a good fight, mm? She was always too feisty for her own good." I was
    tempted to gut him right then and there, but though we Ranid may be a slimy folk, we don’t kill our
    clients. Bad for business. And… I supposed you could say he was an old friend. And I didn’t have
    many of those. Well... truthfully… Rezakor was my only friend.
    `
  },
  {
    text: `I tossed the dagger to Rez, he caught it with his eyes still closed and slipped it into his robes. Show
    off. Rez could have been a handsome lad. That is, if not for the venomancy mutations and witchcraft
    that turned his eyes bog-yellow and his flesh a sallow pea color. On his head hung long, moist,
    soot-black appendages that resembled tendrils more than hair.`
  },
  {
    text: `He smirked at me then, in that know-it-all way he does, his sharp teeth as yellow as his eyes, "Let’s
    have a look at it then, don’t be shy." I sighed and showed him my arm.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/croak/take_a_bath.jpg`
  },
  {
    text: `"How bad it is it, Rez?", I asked. He frowned and clicked his teeth. "Welp, seems you won’t be using
    this arm ever again. Take off your mantle, let me see how far it’s spread."`
  },
  {
    text: `"Wait, what? That’s it? We can’t save it?", I exclaimed, fairly panicked though I tried to play it off.  What good is a Ranid assassin without an arm to hold a blade.
    `
  },
  {
    text: `I took off my cloak as Rez examined me. The flesh rot had spread over my shoulder and started to
    make its way over my chest.
    `
  },
  {
    text: `"Fraid not, bog brother. And if you don’t get this curse lifted soon, it’ll spread to your other limbs by
    nightfall and you’ll be a hollow husk by dawn." I stared at Rez incredulously and screamed at him,
    my voice more shaky than I’d prefer. "And how in Ark’dun do you suppose I lift this curse?! I’ve
    grown fond of living, and would prefer to continue doing it with at least one of my limbs intact."`
  },
  {
    text: `Rez chuckled then, and I must say it took quite a large measure of willpower to keep from slapping a
    tooth loose. "Quite ironic you should mention the Ark’dun. That’s exactly where you’re going.", he
    quipped. Rez retrieved a small frog-like creature from some inner pocket in his soiled robes, popped
    it into his mouth and chewed vigorously.`
  },
  {
    text: `"No. Not happening. Anywhere but Ark’dun. You know as well I, that land is forbidden to the Ranid.
    And more importantly… it gives me the creeps. What are my other options?", I asked.`
  },
  {
    text: `Rez shook his head and popped another green frog into his mouth. He’d mastered the art of
    chewing specifically to annoy me. "There are none. The Maggorot Curse is not a simple spell or
    cheap conjuration. This is deep, dark magic, Crevlos. Magic far beyond even a venomancer’s
    abilities. To counteract the curse, you’ll need magic far deeper and far darker. You need the power
    of the Void-Augur of Ark’dun." I crossed my arms defiantly… well, the one working arm. "No. Not
    going to happen. I refuse. And there’s nothing you can do to make me change my mind."`
  },
  {
    linebreak: true
  },
  {
    text: `Thankfully the trek to Ark’dun was less than a few leagues away. I could feel the Maggorot slinking
    down my chest, into my hip and upper legs. Nightfall would be in a few hours. I needed to get in, do
    the deed, and get out. Just like any other job. Only this time, I was going into forbidden territory into
    one of the most terrifying places on the face of Verdia. I ran the words of Rezakor back in my mind
    for the fifth time since I left him.
    `
  },
  {
    text: `"You must raid a tomb of the Ark’dun. Inside you will find the walls lined with large urns. These urns
    hold the essences of the Ark’dun that have come before. You are looking for a special urn. While all
    the others will be black, inlaid with silver, the urn you want will be wrapped in amaranthine silk. This
    urn holds the soul of the Void-Augur, the greatest sorcerer of his era. But beware, Crevlos, the tomb
    is guarded by the Cryptwarden, and trust me when I say, even a Ranid Assassin of your skill will be
    no match for it. Get the urn and get out of there as stealthily and as quickly as possible."
        
        `
  },
  {
    text: `Sometimes Rezakor could ruffle the proverbial feathers, but I trusted him and he knew his lore. I
    knew better than to ignore his insights.
    `
  },
  {
    text: `The land of Ark’dun was a barren, cold, hollow land of ruins and dead things. Though it sat in the
    heart of the swamp, it seemed all water and life dried up here. The trees were petrified and twisted.
    The ground was dry as sand, black as obsidian. The wind howled like a tortured animal.`
  },
  {
    text: `Legend has it Ark’dun used to be a great Empire centuries ago in the ancient past before the Deep
    Night. A land alive with powerful sorcery and void magic. Some unknown blight befell them and
    cursed their land, leaving it a skeleton of its former glory.
    `
  },
  {
    text: `Time was running out and I had a soul to steal. The tomb itself was more like a mausoleum, a
    brooding, monolithic silhouette against the cool gray sky. I approached the entrance tentatively,
    expecting some nightmare to come screaming out of the dark, but I was only met with silence. That’s
    what made it so eerie. There were no night sounds, no owls hooting, no crickets chirping. Only that
    infernal howling of the wind.`
  },
  {
    text: `The door to the tomb was ajar and I pushed against the cool slate door and was met by a blast of
    musty, tepid air. I was sure no one had breathed this air for half a century. There was no light, but I
    wove a simple Ranid cantrip to allow me to see in the dark.
    `
  },
  {
    text: `The walls were lined with sarcophagi. Almost like verticals open coffins. Inside each of these coffins
    where empty mantles. Above each coffin was a black, silver-inlaid urn. These must be what Rezakor
    spoke of.`
  },
  {
    text: `I crept silently looking for the amaranthine urn. There it was, in the center of the room, sitting on a
    black ornate pedestal with the design of some tentacled nightmare. Next to it was another
    sarcophagus but this one was different. There was no empty mantle but some strange creature
    inside of it. It seemed to be frozen or inanimate.
    `
  },
  {
    text: `Curiousity got the best of me and I slowly crept closer to inspect. The creature was wrapped in
    bandages, was hooded, and had an animal skull where its head should be. One word was engraved
    above its head in the black slate of its coffin but it was written in Ark’dunian and I couldn’t read it.
    `
  },
  {
    text: `I swear I saw the figure stir. I didn’t wait for it to greet me. I swept the urn up and bolted away from
    there as fast as I could. As I passed through the exit I heard a large cracking sound and ghastly
    voice howled after me, "Thief! Thief, return what is mine!"`
  },
  {
    text: `When I finally stopped running I had no idea where I was. My heart thudded in my chest like a rabid
    weasel. My lungs burned as if they were filled with acid. I was far away from Ark’dun, several miles
    at least, but I still had a funny feeling I was being pursued. I finally looked down at my prize. A small
    urn wrapped in amaranthine silks. I realized then that bloody fool Rezakor never told me what to do
    once I stole the urn.
    `
  },
  {
    text: `I tore off the silks and revealed the golden container. It was relatively clean if not for a small smudge
    of dirt on the side. I rubbed the urn against my leathers and it started to glow with purple energy and
    burn so hot I had to drop it. A purple-black mist erupted from a small hole in the top and I was
    greeted by large violet eyes and a shadowy apparition.
    `
  },
  {
    text: `The ghostly cloud spoke, "Has the darkest dawn arrived? Have the Ark’dun emerged at the end of all
    times?`
  },
  {
    text: `The violet eyes took me in for a moment and realization came over it.
    `
  },
  {
    text: `"What in Agruul’s name is going on here! What- How did you summon me, human? You stole my urn
didn’t you! What need does a swamp-rat have of the Void Augur of Ark’dun." The words hung in my
throat at first, but I finally spat them out.I was severely out of my element here. But I couldn’t let it
sense any fear in my voice. " I’ve captured you, Void Augur. I, Crevlos of the Ranid, slayer of men
and creature, need- er demand your assistance. I- You see…"`
  },
  {
    text: `I lifted my cloak to reveal the Maggorot now fully spread across my chest and stomach down to my
    hips.
    `
  },
  {
    text: `The apparition laughed uproariously at my plight. "Well, it seems I am yours to command, Crevlos of
    the Ranid, as you seem to hold my soul in your hands. But I do not think you realize what you have
    done. He will never stop hunting you. Ruh Kaan will pursue you to the ends of Verdia until you’ve
    returned my urn to the tomb. And he will not stop, will not tire until he has ripped your soul from your
    body and devoured every last morsel of your essence."`
  },
  {
    text: `I ignored the lump rapidly forming in my throat."The Maggorot, can you cure it or not, phantom?"
    `
  },
  {
    text: `The Void Augur’s large purple eyes scanned my torso. "You do not have long, human. The form you
    have now is cursed, this cannot be changed, cannot be undone. But… I can give you a new form,
    uncursed and unspoiled. Is this what you wish? What is done, cannot be undone. Choose quickly." I
    nodded, unsure of what strange fate awaited me.`
  },
  {
    text: `The apparition of the Void-Augur quivered and spoke, "Very well." Suddenly the apparition formed
    into purple and black gas, surrounded me, and poured into my mouth, into my lungs. I coughed and
    gasped for air, I felt as if I was drowning in darkness. I fell to my knees. My vision grew blurry.
    `
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/croak/tfw_smoke_out_ears.jpg`
  },
  {
    text: `Before I passed out, I made a sound I’d never forget. It roiled in my gut and erupted out of my
    mouth, echoing in the night air….`
  },
  {
    text: `"CROAAK!!!"`,
    style: {
      textAlign: "center"
    }
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/croak/CROAAAAAAAKING_IN_MY_SKIIIIIIIIIIIIIN.jpg`
  }
];

export default content;
