import locations from "./locations";
import arenas from "./arenas";

const pins = [
  // LOCATION PINS
  {
    x: 1865,
    y: 1732,
    location: "Anjan Steppes",
    champions: ["taya"]
  },
  {
    x: 2559,
    y: 1476,
    location: "Daharin",
    champions: ["ulric"]
  },
  {
    x: 1678,
    y: 1047,
    location: "Ga'Tamang Empire",
    champions: ["ruh_kaan", "croak", "bakko"]
  },
  {
    x: 1670,
    y: 1308,
    location: "Swamp of Malevolence",
    champions: ["ruh_kaan"]
  },
  {
    x: 3572,
    y: 1829,
    location: "Kingdom of Quna",
    champions: ["raigon", "shifu"]
  },
  {
    x: 1195,
    y: 677,
    location: "Othagur",
    champions: ["pestilus"]
  },
  {
    x: 2500,
    y: 1778,
    location: "Eastern Wastelands",
    champions: ["rook", "jade"]
  },
  {
    x: 1836,
    y: 1424,
    location: "Toleen",
    champions: ["ezmo", "lucie", "zander"]
  },
  {
    x: 2505,
    y: 944,
    location: "Northern Wildlands",
    champions: ["ashka", "jumong", "freya"]
  },
  {
    x: 1173,
    y: 1542,
    location: "Mt. Silverridge",
    champions: ["poloma", "thorn", "blossom"]
  },
  {
    x: 1762,
    y: 2786,
    location: "Trolobe",
    champions: ["varesh"]
  },
  {
    x: 1834,
    y: 2476,
    location: "Tur-Karborun",
    champions: []
  },
  {
    x: 1888,
    y: 1144,
    location: "Arasta-Tor",
    champions: []
  },
  {
    x: 2592,
    y: 2208,
    location: "Lokum",
    champions: ["pearl"]
  },
  {
    x: 3711,
    y: 806,
    location: "The Outer Realms",
    champions: ["iva"]
  },
  {
    x: 2923,
    y: 1020,
    location: "Ancient Grounds",
    champions: []
  },
  {
    x: 1850,
    y: 618,
    location: "Faran Gol",
    champions: []
  },
  {
    x: 2412,
    y: 645,
    location: "Na'Gol Mountains",
    champions: ["ashka"]
  },
  {
    x: 2227,
    y: 365,
    location: "Frozen North",
    champions: ["alysia"]
  },
  {
    x: 1191,
    y: 521,
    location: "Dur Kuthur",
    champions: []
  },
  {
    x: 2415,
    y: 1400,
    location: "Monastery of Resonance",
    champions: ["ulric"]
  },

  // ARENA PINS
  {
    x: 1280,
    y: 565,
    arena: "Blackstone"
  },
  {
    x: 1340,
    y: 1555,
    arena: "Orman Temple"
  },
  {
    x: 2175,
    y: 1960,
    arena: "Mount Araz"
  },
  {
    x: 3637,
    y: 1580,
    arena: "Dragon Garden"
  },
  {
    x: 1754,
    y: 2333,
    arena: "Sky Ring"
  },

  // CHAMPION PINS
  {
    x: 1500,
    y: 1300,
    champion: "ruh_kaan",
    pinStyle: {
      color: "white"
    }
  },
  {
    x: 3532,
    y: 1649,
    champion: "raigon",
    pinStyle: {
      color: "white"
    }
  },
  {
    x: 1900,
    y: 1500,
    champion: "ezmo",
    pinStyle: {
      color: "white"
    }
  },
  {
    x: 2012,
    y: 755,
    champion: "freya",
    pinStyle: {
      color: "white"
    }
  },
  {
    x: 2532,
    y: 655,
    champion: "ashka",
    pinStyle: {
      color: "white"
    }
  },
  // {
  //   x: 3532,
  //   y: 1949,
  //   champion: "new_phone_who_dis",
  //   pinStyle: {
  //     color: "white"
  //   }
  // },
  {
    x: 1063,
    y: 1662,
    champion: "blossom",
    pinStyle: {
      color: "white"
    }
  },
  {
    x: 1730,
    y: 1380,
    champion: "croak",
    pinStyle: {
      color: "white"
    }
  },
  {
    x: 1840,
    y: 970,
    champion: "bakko",
    pinStyle: {
      color: "white"
    }
  },
  {
    x: 1100,
    y: 650,
    champion: "pestilus",
    pinStyle: {
      color: "white"
    }
  },
  {
    x: 2400,
    y: 1150,
    champion: "jumong",
    pinStyle: {
      color: "white",
      textAlign: "center"
    }
  },
  {
    x: 3411,
    y: 1006,
    champion: "iva",
    pinStyle: {
      color: "white",
      textAlign: "center"
    }
  },
  {
    x: 1800,
    y: 1530,
    champion: "lucie",
    pinStyle: {
      color: "white",
      textAlign: "center"
    }
  },
  {
    x: 2127,
    y: 305,
    champion: "alysia",
    pinStyle: {
      color: "white"
    }
  },
  {
    x: 3402,
    y: 1849,
    champion: "shifu",
    pinStyle: {
      color: "white"
    }
  }
];

const mapPinsToLocations = () => {
  return pins.map(pin => {
    pin.location =
      locations.find(location => location.name === pin.location) || null;
    pin.arena = arenas.find(arena => arena.name === pin.arena) || null;
    return pin;
  });
};

export default mapPinsToLocations();
