const content = [
  {
    title: `Iva’s Big Find`
  },
  {
    text: `I was woken up by a rough sandstorm today. The howling wind rocked the makeshift tent with the fury of a warthog throwing tantrum. It soon passed, and I gathered up my tent and made my way through the sand dunes - seeing what I could see, finding what I could find. Anything useful went in the bag. Anything edible went in the belly. But you didn't find much, this was a remote region even for the Outer Realms.`
  },
  {
    text: `A few days ago I was strolling through a dense jungle alive with various plants and critters. Humongous insects the size of hogs tittered and skittered around; plants that craved nothing but flesh hung silently from branches overhead. I kept on going like I always do, scavenging what I can. Finding food where I can. If I'm anything, I'm a survivor. A survivor with an achy hip. As I trudged on my surroundings began to shift.`
  },
  {
    text: `The land became more barren with every mile that I hiked. Humidity soon disappeared as an intense dry heat took its place. The beasts became fewer in number but much bigger in size. Massive, monstrous, lumbering lizards - downright scary. Thank the heavens they didn't see me.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/iva/Iva01.jpg`
  },
  {
    text: `As I kept moving along, everything around me was just getting weirder and weirder. Crystalline shards started appearing in the sand beneath my feet. They looked like shards of black glass but had a deep violet sheen to them. Naturally, I picked a few of them up.`
  },
  {
    text: `I brought two of them close and they reached out to each other with a dazzling electric arc, purple in hue. I felt the surge of electricity in my fingers - it was tingly, almost painful even. I gathered up a few big ones to see if I could put them to good use later on.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/iva/rich_af.jpg`
  },
  {
    text: `The further I walked, the more of these shards I saw. There was more of them, and they were more substantial too. Soon enough they were littering the ground everywhere, sending sparks back and forth to each other like the ground was a vast circuit board. I moved closer to study a pillar of the black glass off in the distance.`
  },
  {
    text: `Once I was only a few feet away, I realized that this was no pillar. It was a beautiful statue of a girl, bare with a frozen expression of fierceness and pain. It was so lifelike - she looked as real as any human. Almost as if it was an actual person petrified by some curse.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/unknown/THESE_WOUNNNNDS_THEYY_WILLL_NOT_HEAAAALLLLLLLLL.jpg`
  },
  {
    text: `The statue’s figure was a work of art in itself. The shiny black glass skin gleamed as tiny threads of lightning danced around its body. The eyes were pale white, like milky pearls with swirling violet streaks. The chest was caved in, like it had been hit by something even harder than itself. The rib cage was cracked inward, leaving a cavity. Inside I could see her heart. It was not only arcing red electricity but also had glowing, plasmatic tentacles writhing out of it, waving like a struggling octopus. I hesitantly reached out my hand to touch it  when I heard a bloodcurdling growl.`
  },
  {
    text: `I turned around quickly with ole Bessy in my hands prepared for anything. With a deafening roar a hulk of horns and scales lunged towards me. With inches to spare I triggered my jetpack and shot up into the air, avoiding a lethal pounce from the terrifying beast. My jetpack must have sustained some damage from my last fight though, as oil shot out of it and spilled all over the ground. I tell ya though, I'm glad it did! It actually worked in my favor as the beast slipped and smashed itself against the statue.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/iva/Iva04.jpg`
  },
  {
    text: `Its massive head was pale yellow, with horns curving in all directions. This beast was the size of an ox but with rough scales and three glowing eyes. Its tail was long and reptilian-looking with sharp spikes. I gotta admit that thing was ugly, even for a mutant beast.`
  },
  {
    text: `I launched one of my rockets at the creature's head, hoping to obliterate it entirely. Direct hit! But the beast just shrugged its head and looked at me, only slightly perturbed. Those scales sure could take a beating! The oil it was covered in caught fire from the rocket blast, but this just pissed the monstrosity off even more as it screamed shrilly and charged straight at me again.`
  },
  {
    text: `I fired off ole Bessy a few times, but it was not deterred. I had to find a weakness and quick. I launched myself back into the air to avoid a claw swipe when I saw something out of the corner of my eye. A group of bandits was closing in on the statue.`
  },
  {
    text: `They approached with vicious grins and drawn weapons. Their intentions were clear enough and at any rate, you don't get to my age by being naive. I found the statue first and hell if I was going to let someone else steal it from under my nose! I had the full attention of the abomination below me, and I was going to use it to my advantage. I rocketed in the direction of the mob, still spilling that oil everywhere. The creature chased me from below as I flew over the group of bandits. Its massive legs trampled all over them like they were nothing but ants. Suddenly a loud gunshot rang out and just like that the beast was down.`
  },
  {
    text: `I spun towards the direction of the gunshot. There stood a tall fella in a long black coat and a gaunt, expressionless face. He slowly walked toward me, carrying a long sword-rifle combination. The way he carried himself made it obvious he was the leader of the band. The man seemed quite annoyed at best. He was not my only problem either.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/iva/how_does_he_aim.jpg`
  },
  {
    text: `A small group of his thugs remained, and they were all making their way closer. I wasn't sure what race they were. They were humanoid in appearance but all had some sort of weird stuff going on. Some were missing limbs while others had a few extra. A couple even shared the same body. Mutants then, changed by living out in the Outer Realms. That's why I wore a mask. Don't wanna wake up with an extra arm where there ain't supposed to be one.`
  },
  {
    text: `A few of them lunged at me with swords and makeshift clubs. One of them was using some kind of homemade bone claw. I didn't have time to dilly dally. I unloaded my machine gun and took two out with a long volley. The bastards were quite resilient if anything. I dared not fire a rocket at them at this close range - it would have blown myself up too. I unloaded a few rounds from ole Bessy instead. Another mutant hit the dirt, while the third could barely move.`
  },
  {
    text: `Just then I heard another gunshot and felt a bullet graze me, burning a streak into my flesh. Instinctively I activated my shielding contraption. Just in time too, as the electric barrier absorbed a few more bullets in a shower of sparks. I aimed my tractor beam at the remaining mutant, pulling him close and readied Bessy. When I had him at a nice and close range, I gave him a blast to the face. He went down, and I gotta say it was an improvement to his scary mug.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/iva/Iva06.jpg`
  },
  {
    text: `I had completely forgotten about the fellow in the dark duster-coat. I looked toward the direction of the statue I was examining, and there he was. I tried my best to get over to him, firing up my jets once again but I was too damn slow. He reached into the statue’s chest and ripped out its crystalline heart.`
  },
  {
    text: `He screamed in agony as the heart sent bolts of lightning through his arm. It encased the man’s hand with a purple plasma that slowly changed into a crimson red. It looked like a hardened cast on his arm and clenched fist, and then black spines burst out through the cast. The man was still yelling as red lightning arced all around him. Next thing I knew, there was a massive explosion and my old bones were hurled into the air.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/iva/boi_vs_anime.jpg`
  },
  {
    text: `I woke up sometime later with some back and shoulder pain. More than the usual. The man was gone, the statue was nothing more than broken shards, and the ground was littered with body parts of the fallen bandits. The explosion must have ripped them apart. Even the beast lay there with its underbelly ruptured. I made my way to it, thinking I could use its scales for some better armor when I saw something interesting poking out of its busted belly.`
  },
  {
    text: `Apart from the intestinal sludge and some bones, there was a piece of something that looked like a mask. There was something familiar about it, something I'd seen before. I put down my backpack and started rifling through it. “Ah-HA! I found it!” I proclaimed. I got out the medallion that I had almost forgotten about.`
  },
  {
    text: `I placed them side by side. They shared the same symbol, and it all started coming back to me. The insignia on the medallion, the people in masks around me muttering in hushed voices. A woman wrapping me up, hiding me away, crying about a fire gift that I didn't have. And a dream of a living flame, leaving me with a smoldering warmth in my chest. The woman had worn a mask much like this one found in the belly of a beast. Maybe, just maybe this mask connects me to my homeland. To my people.`
  },
  {
    text: `All these hundreds of years I've wandered the Outer Realms with no purpose but living another day. I didn't choose to live this hard, lonely life, just scavenging and surviving, but now I was able to make a choice. I gathered my things and wiped the soot off ole Bessy. I was heading to the big city to find out where this mask came from - and nothin's gonna stop me.`
  }
];

export default content;
