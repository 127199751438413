import React, { Component } from "react";

export default class Redirect extends Component {
  componentDidMount() {}

  render() {
    if (
      (this.props.history.location.pathname !== "/map" &&
        !this.props.history.location.pathname.includes("/stories") &&
        this.props.history.location.pathname !== "/") ||
      (this.props.isMobile && this.props.history.location.pathname === "/map")
    ) {
      this.props.history.push("/");
    }

    return <div />;
  }
}
