const locations = [
  {
    name: "Anjan Steppes",
    text: `The Anjan Steppes consist of dry steppes, vast grasslands and soft rolling hills. It is home to various peoples, both nomadic and domicile.
      The steppes are home to the notorious band of brigands known as the Razorbacks, who roam the plains and plunder unsuspecting caravans. They are both scorned as thieves and ruffians while being romanticized as unfettered free-spirits.`
  },
  {
    name: "Daharin",
    text: `Daharin, more formally ‘The Great River Valley of Daharin’, is the lands that lie in the valley of the great river. It is surrounded by mountain ranges and highlands, and to the west lie the coast of the Longhorn Bay and the free city of Toleen. 
      The land is made up of hundreds of independent dukedoms and baronies, held together by their common culture and religion known as ‘The Symphony’.  The Symphony has it's seat of power in the great Cathedral of Resonance, in the heart of the River Valley, from there its Paladins and Guardians spread the word of order and harmony.`
  },
  {
    name: "Ga'Tamang Empire",
    text: `A great empire located in the north-west. In bygone times before the Deep Night it stood as the mightiest empire in the world, and it is still a force to be reckoned with. 
      The people of Ga’Tamang might be somewhat behind other civilizations in terms of technology, but they make up for it with other virtues. Strength, bravery, honor and martial prowess are celebrated ideals.
      Foremost in their armies stands the Vanguard, the elite warrior caste of the Empire. They follow a strict martial code and can always be found at the front and centre of every battle, be it against barbarian raiders from the Wildlands or their eternal enemies of Othagur.`
  },
  {
    name: "Swamp of Malevolence",
    text:
      "A place few dare enter, and fewer yet return from. The dense swamp at the southern coast of Ga’Tamang is technically a part of the Empire, but the rule of the emperor means nothing there. It is home to all manner of deadly threats, from stalking predators to flesh eating plants and cannibal savages, and in ancient tombs and ruins lurk darker horrors still.",
    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Swamp_of_Malevolence_01.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Swamp_of_Malevolence_02.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Swamp_of_Malevolence_03.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Swamp_of_Malevolence_04.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Swamp_of_Malevolence_05.jpg"
    ]
  },
  {
    name: "Kingdom of Quna",
    text: `The mighty Kingdom of Quna lies to the far east, beyond the Eastern Wastelands and the Forest of Shadows.  It is a fertile land of marshlands, green hills and lush forests. Because of its remoteness there has until recently been little contact between Quna and the western lands. But the inauguration of the Dragon Garden arena and the opening of the Eastern Road
have brought the two parts of the world closer together. Quna is ruled over by the Dragon King, counselled by advisers from both near and far.`
  },
  {
    name: "Othagur",
    text:
      "The mountainous region north of Ga’Tamang is home to a cruel and wicked civilisation that worships dark gods through blood magic and sacrifice. The remote, inaccessible land and its subterranean cities carved out the bedrock serve as a fortress from where the ruling Bloodpriests scheme and plot to extend their influence.",
    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Othagur_02.jpg"
    ]
  },
  {
    name: "Eastern Wastelands",
    text:
      "The Eastern Wastelands lie on a massive plateau south of Daharin. Except for the fertile southern coastline, it is a wasteland of sterile deserts and mazes of razor sharp crags. It’s plagued by draught and scorching heat, monsters that can swallow a man whole and sandstorms that can strip the skin from his body. Despite this, the wastes are still home to various barbarian tribes, and in the few more hospitable places lie even towns and civilization."
  },
  {
    name: "Toleen",
    text:
      "Built upon the ruins of the ancient Sunderthorn empire, the Free City of Toleen has been a trading hub for the whole world for centuries. Located at the coast by the Longhorn Bay, Toleen is a bustling melting pot of commerce, crime and innovation. It is home to the famous Academy of Toleen, where the world’s greatest alchemist and scientists gather to unlock the secrets of the universe."
  },
  {
    name: "Northern Wildlands",
    text:
      "To the east of Ga’Tamang lies vast, untamed wildlands comprised of mountain ranges, endless forests, tundras, ancient ruins and fallen civilizations. Once home to mighty kingdoms and glorious cities, it is nowadays sparsely populated by barbarian clans and uncivilized wildmen."
  },
  {
    name: "Trolobe",
    text:
      "Trolobe is a desert kingdom far to the south, beyond the violent sea called The Abyss. It’s an advanced civilization, both when it comes to their magically powered technology and when it comes to the society. For long, Trolobe remained unvisited by the peoples of the northern continent. Although travel across the sea is nowadays possible, albeit dangerous, much of Trolobe is still shrouded in mystery."
  },
  {
    name: "Mt. Silverridge",
    text: `To the west of the Anjan steppes on the westernmost part of the peninsula stand the snow covered peaks of mount Silverridge, gleaming like silver in the sun. It's a place of strong powers, where the threshold to the spirit world is less steep and the laws of nature not as rigid as elsewhere. The peaceful Diom people live on the mountain sides, in harmony with both beasts and spirits.
    Below the glistening summits encircling the mountain lies the Silverdeep forest. The legends that surround it are many and tell of both wondrous magical beings and unfathomable terrors.`,
    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Silverridge_01.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Silverridge_02.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Silverridge_03.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Silverridge_04.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Silverridge_05.jpg"
    ]
  },
  {
    name: "Monastery of Resonance",
    text:
      "The many hundreds of churches and abbeys that are dotted throughout Daharin are impressive buildings in their own right, but none of them come anywhere close to the splendour of the Monastery of Resonance. It is the seat of power for the religion of Symphony and the base of operations for its preachers and paladins as well as other more covert agents.",
    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Monastery_of_Resonance_01.jpg"
    ]
  },
  {
    name: "Dur Kuthur",
    text:
      "Endless tunnels carved out of the mountain like a termite nest form the subterranean capital of Othagur. What dark secrets its deepest chasms hide are known only to the ruling council known as the Brotherhood of Bile."
  },
  {
    name: "Frozen North",
    text:
      "Beyond the Na'Gol mountains is the land of permanent winter, ice and snow. It is a place where no plants grow and few beasts roam, but otherworldly beings can be seen drifting across the silent wastes under the dancing northern lights."
  },
  {
    name: "Na'Gol Mountains",
    text:
      "The massive Na'Gol mountain range in the north stretches from the lands of Ga’Tamang in the west to far beyond the borders of the known world to the east. The mountains are home to the enigmatic Tioko people who live underground deep beneath the bedrock.",
    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/NaGol_Mountains_01.jpg"
    ]
  },
  {
    name: "Faran Gol",
    text:
      "Faran Gol was a city on the westernmost fringes of the Na’Gol mountains. It was famed for its Academy of Storms where wizards trained to master the forces of Storms and Thunder. It was razed during the great cataclysm that led to the Deep Night, and now ruins are the only witness to its former glory."
  },

  {
    name: "Ancient Grounds",
    text:
      "Far to the east lie the withering ruins of temples and sacred places dedicated to ancient heroes and gods. Older than any current civilization, their true purpose has been lost to the mists of history.",

    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Ancient_Grounds_01.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Ancient_Grounds_02.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Ancient_Grounds_03.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Ancient_Grounds_04.jpg"
    ]
  },
  {
    name: "The Outer Realms",
    text:
      "The Outer Realms is the unmapped lands that lie beyond the Ancient Grounds. Very little is actually known about these places but rumours and legends are plentiful."
  },
  {
    name: "Lokum",
    text:
      "Shielded from the violent torrents of the Abyss behind the Aenaros Archipelago lie the calm waters of the Azure sea. Under the clear-blue waves is the city of Lokum where the gods of the oceans are worshiped."
  },
  {
    name: "Arasta-Tor",
    text:
      "The capital of the Ga’Tamang Empire is a veritable fortress whose battlements are manned by the elite Vanguard. It is surrounded by palisades and the natural moat formed by the river Finremer."
  },
  {
    name: "Tur-Karborun",
    text:
      "The seemingly impossible architecture of the capital of Trolobe stands as a testament to the achievements of the Trolobean civilization. Built in ancient times using arcane magi-tech, its towers stretch towards the sky, connected by spindly bridges to gravity-defying, floating islands."
  }
];

export default locations;
