const content = [
  {
    title: "The Loneliest Shadow"
  },
  {
    text: `"Before the maligned effulgence of the Sun kindled for the first time upon
    Verdia. Before the trifling beasts and human-ilk milled and rutted the earth.
    Before the Spawn of Amargah’s germinal twinkling of life on this misbegotten
    land... there was naught but the Womb of Darkness. 
    And it is in this tenebrous void, the whisper of Ark’dun coiled and writhed in
    the inky depths. We were born in the hidden, secret chasms of the world, far
    beyond the sight of the Gods. And when this world has aged, withered and
    decayed to rot, we, the Ark’dun, will still be here… waiting, growing and
    scheming… in the Dark."`
  },
  {
    text: `The First Precept of Ark’dun`,
    style: {
      fontStyle: "italic",
      fontWeight: "bold",
      textAlign: "center"
    }
  },
  {
    text: `- Void-Augur Nas’Gorun`,
    style: {
      fontStyle: "italic",
      fontWeight: "bold",
      marginTop: "-40px",
      textAlign: "center"
    }
  },
  {
    text: "They told me I would be worshiped. That I would be deified."
  },
  {
    text: `They told me my people would laud the name of Ruh Kaan for eternity. I was to be a Crypt Warden,
    esteemed guardian of the Tomb of Nas’Gorun.`
  },
  {
    text:
      "They did not tell me I would be a prisoner. A slave to an empty, hollow mausoleum."
  },
  {
    text: `I drift in an endless, dreamless black sea of nothingness. Time washes over me like silent waves,
    cresting and falling as decades pass by. Yet here I remain, motionless, frozen and numb.`
  },
  {
    text: `My vigil is not without import. I guard the essences of my brothers and sisters. Black Urns, inlaid with
    silver and crafted with meticulous care, line the walls. Each unique shape reflecting the unique soul
    of the Ark’dunian that lies within it. We of the Ark’dun may have cast away our mortal vessels to
    wither and die, but our souls are endless. These souls are called our Verum, and they are immortal.`
  },
  {
    text: `You see, we of the Ark’dun may have tombs akin to other races on Verdia, but our tombs serve a
    much different purpose.`
  },
  {
    text: `When the Gods fought, fell and cast the world into the long Deep Night those many years ago, our great Oracle Nas'Gorun foresaw this cataclysm. He, in his infinite wisdom, gave the command to
    seal our Verum in our tombs, safe and silent, until the time was ripe for Ark’dun to rise again.`
  },
  {
    text: `Dark-shaper Agruul, the first Father, and guide of our clan - wielder of the Scythe of Malevolence.
    Void-Augur Nas’Gorun, the great philosopher, and speaker to the Void. Wadjongo the Nighthammer,
    Crafter, and Smith of Ark’dunian weapons and artifacts. All have shaped the legend of our people in
    their visage. All passed beyond the Mantle of the Vessel. And here I stay in eternal solitude.`
  },
  {
    text: `Here I tilt on the precipice of sanity. A shadow among shadows.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ruhkaan/BookOfLoreRuhKaan.jpg`
  },
  {
    text: `Then, suddenly and inexplicably, the most curious thing occurred. Something that has not happened
    for centuries. Someone entered my tomb.`
  },
  {
    text: `At first, I thought it a mere trick of the light. Creatures do not willingly enter the realm of Ark’dun.
    Never their tombs and most assuredly never the grandest and most macabre of them all. But here
    we were nonetheless. Some poor fool entered my tomb.`
  },
  {
    text: `Though to an outside eye my tomb would be lightless, nothing in my tomb was hidden from me. The
    Ark’dun were one with the dark, with shadow. The creature stealthily, almost pitifully, kept to a
    presumed cover.`
  },
  {
    text: `He was human, that much I could clearly see. I had not seen another lifeform for centuries but there
    was something familiar about him. Perhaps his clothing. I had seen the like before. He belonged to an
    order of some renown. Swamp assassins. Ah yes, it came to me then.`
  },
  {
    text: `He wore the cloak of the Ranid.`,
    style: {
      textAlign: "center"
    }
  },
  {
    text: `I watched him creep on nervous footfalls. Silent, yes, but full of fear with every step. And then I could
    feel myself… awakening. The hold on my body loosened. I could feel the once invisible shackles
    crumbling away like sand. Life was returning to me. Purpose was returning.`
  },
  {
    text: `The Ranid approached the urn of the greatest of us, the Void-Augur. He snatched the urn, tucked it
    into his cloak and bounded for the exit. I tried to reach out to him then, to stop him, but I had not yet
    regained control of my physical form. Still, life was returning to these limbs. Life... and something
    else. Something I had not remembered. Something I did not know to miss. A violent, almost
    maddening ache.`
  },
  {
    text: "A hunger.",
    style: {
      fontStyle: "italic",
      fontWeight: "800",
      textAlign: "center"
    }
  },
  {
    text: `A Crypt Warden can never leave his tomb, but the poor Ranid fool had taken a part of my tomb
    with him and wherever he went, I could now follow. And as I freed myself from my sarcophagi, I smiled.
    Now I had a purpose. Now I was no longer a prisoner. There would be time to hunt the thief. To
    return what he had stolen. To rend his soul from his flesh. To feel his bones crack and shatter
    beneath my grasp.`
  },
  {
    text: `But for now… with newfound life comes newfound needs. One must feed if one must live.`
  },
  {
    text: `And I was very, very hungry.`,
    style: {
      // fontFamily: "Indira_K ",
      fontStyle: "italic",
      fontWeight: "bold",
      textAlign: "center"
    }
  }
];

export default content;
