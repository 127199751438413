const content = [
  {
    title: `Leaving Home - Blossom’s Tale`
  },
  {
    text: `My home is a land of endless wonder and boundless beauty - a magical place known as the Silverdeep Forest.`
  },
  {
    text: `I’ve lived there all my life. When I was a young fawn, I would spend my days prancing around and getting into all sorts of mischief. At night, I would often frisk beneath Ahmrilas’ boughs and watch the moonbeam butterflies flicker as my sisters and forest friends played music and sang songs.`
  },
  {
    text: `Ahmrilas was an ancient tree, the only of his kind, and, according to Mother, had lived for thousands of years. He was no ordinary tree, but it was not just his long time in this world that made him special.`
  },

  {
    text: `Ahmrilas had a special aura around him that you could feel with your very spirit. It was commonly known that if you would sleep one night beneath Ahmrilas, by the rising of the great Golden Skygem, any ailment you had would be healed. Ahmrilas’ healing aura was not the end of his gifts, but I will get to that later.`
  },
  {
    text: `One particular evening, when the Golden Skygem’s light was waning, I was startled by a peculiar cry of distress coming from deep within the forest. It sounded avian, but even though I knew all of the sounds from all of the birds that dwelt in my forest, this one I did not recognize. I picked up my trusty staff and ran as fast as my hooves could carry me. Like Ahmrilas, healing was one of my gifts, and if I could help this poor creature I surely would.`
  },
  {
    text: `I slid down muddy slopes and skipped through bubbling streams, carefully and quickly leaping on the stepping stones. I was getting close as the call of the creature amplified in intensity. With a calculated bound, I made it over a massive fallen tree and at last found the distressed little thing.`
  },
  {
    text: `It was indeed a bird, but one I’d never seen before, caught in the trap of the Maiming Margarid. A devious flesh-eating plant, the Maiming Margarid incapacitates unsuspecting creatures with its paralyzing thorns and feeds off of them as they slowly sink into the soil. Mother always told me, "as wonderful as all life in our Forest can be, the Forest can be just as unforgiving."`
  },
  {
    text: `This poor creature learned this lesson first hand, or to more accurately put it, first wing. The quick vine of the Margarid had struck and fractured his little wing. The bird was a young one, who most likely had gotten lost and settled down for the night to rest. He stared at me with wide eyes and raised his bushy strawberry-red eyebrows. He was clearly very frightened and shook in terror. Thankfully, I knew just what to do.`
  },
  {
    text: `I pulled out some acorns along with a few leaves out of my medicine pouch given to me by dear Ahmrilas. Gently, I untangled the bird from the vines of the Margarid and sat him down on the soft grass. I ground the acorns and leaves into a paste and gently covered his wounded wing. The liquid from the leaves reacted with the acorns to harden and form a cast, protecting the wound from outside infection.`
  },

  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/blossom/Blossom01.jpg`
  },
  {
    text: `I nestled the strange green bird close to my chest in order to make the difficult journey back less bumpy on the injured animal. He did not utter a peep in objection and in fact, fell asleep shortly.`
  },
  {
    text: `It was night when I got back home and back to Ahmrilas. I held the sleeping bird in my arms as I lay beneath the thick branches and the next morning I found him flapping around without a care in the world, his wing was completely healed. When he saw I was awake, he squawked in delight and flew right on top of my head and settled in as if it was his nest. And it is there he stayed.`
  },
  {
    text: `This was how I met my life-long companion, Maxwell. We have been inseparable ever since. My sisters teased me for the odd green hat that I always wore. But I didn’t mind. This, of course, was Maxwell, resting on his favorite perch.`
  },
  {
    linebreak: true
  },
  {
    text: `So how does a faun like me and a silly green bird wind up fighting in the arenas so far from home?`
  },
  {
    text: `Well… not long ago I was woken up from my mid-day nap from the sounds of a commotion. I sprung to my hooves to investigate what the concern was all about. What I learned was very grave indeed. My sister Rosebud had returned with her scouting patrol party and was pacing around with uncertainty. The Calling Bell rung out as people scuttled to and fro from their homes. A Council had been called!`
  },
  {
    text: `The leaders and lords of the creatures of the forest all gathered together under the boughs of Ahmrilas. My mother, the Queen, was at the head of the circle of Lords. My sisters and I surrounded her. Okairnon the Horned, Lord of Owls spoke out. His great bellowing voice rung out deep and clear.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/blossom/Blossom02.jpg`
  },
  {
    text: `"It has been brought to attention that our beloved Silverdeep forest is in grave danger! Our owl eyes miss nothing: we peer endlessly, from dusk till dawn and till dusk again, surveying our sacred land. And in our endless vigil, our scouts witnessed a sight that beggars belief!"`
  },
  {
    text: `There was a collective gasp among the forest Lords. Lord Okairnon cleared his throat loudly and continued.`
  },
  {
    text: `"Even I must admit… we were such fools to not have taken Mystic Wurmwood’s warnings seriously. The Twisted Terror has returned! The Wood Blight is very real and it has made its way into Silverdeep. I beheld its horror with my own eyes, and it just as awful as Wurmwood had described. Black roots sprouting from the ground, linking together like a parasitic web. Any living thing, flora or fauna, that touches the accursed roots has its life force sucked out as it withers away! It seems to grow each passing light cycle. Ruin is upon us!"`
  },
  {
    text: `The Great Owl bowed and shook his head in horror. The other lords broke out in a riot of proclamations and lamentations. Agnius the Badgerlord pounded his massive fists into the ground causing the earth to shake. "Order! We must have Order!", he shouted.`
  },
  {
    text: `Valika the Snake Mother hissed in agreement. "Yesss sssurely our Queen will make sssense of this chaosss." My mother the Queen nodded solemnly and rose to her full height. All of the creatures drew silent as she spoke. Her voice was a crisp as autumn and as strong as oak.`
  },
  {
    text: `"My lords, these are disturbing tidings and I do know things look very bleak. If we do not find a way to stop this Thorn and heal our cursed land, the very forest of Silverdeep will surely wither away and will be only remembered through tales and fables."`
  },
  {
    text: `The creatures shuddered at this notion.`
  },
  {
    text: `"We must find a way to halt this disease or it might swallow not only our forest home but all good that lives on this Earth. But halting this scourge is beyond our knowledge. That is why we must send one of our own out to the world beyond the forest. There must be someone there who still remembers the songs of the dryads of old, who still knows how to cure our land and fell this cursed Thorn! Who among us is brave enough to leave the forest behind, to go to the cities of men to find the cure to this blight?"`
  },
  {
    text: `I looked around at my fellow sisters and the great Lords of the Forest. They chattered and spoke among themselves but no one volunteered to leave. Could I blame them? None of us had ever left Silverdeep before. This was our home. Our refuge. What would await us beyond our borders?`
  },
  {
    text: `I was horrified at the possibility that everything that I’d ever known and ever loved could be destroyed by a creature we all believed to be nothing more than a myth. I could not let that all be taken away. To see Ahmrilas shriveled and withered to a husk. I would not let that happen!`
  },
  {
    text: `I leaped onto a nearby boulder and proclaimed, "I will go. We have heard the tales of the Arenas, of how the greatest warriors, sorcerers, and healers from all over the world gather there. If there is a way to fight this disease that encroaches on our home, surely it can be learned there. I will train hard and soak up all the wisdom that I can from the Champions there. I promise you, my friends, I will return with the knowledge of how to defeat this Thorn and push back the oncoming plague. I swear it by the Moon and the Earth and the Golden Skygem!"`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/blossom/Blossom03.jpg`
  },
  {
    text: `Mother, the Queen of Silverdeep, looked up at me, tears glittering in her eyes. She smiled at me and nodded knowingly. "My dearest Blossom. I knew that it would be you. You are special, my little one, and your heart is full of courage. Go then, and know that you carry the love of Silverdeep with you."`
  },
  {
    text: `And with that, I gathered my belongings and set out with Maxwell on this journey to save my home. Though the trials ahead will be hard and unforgiving, I will not falter. I am Blossom, daughter of the Queen of Silverdeep, and nothing will stand in my way to save my home.`
  }
];

export default content;
