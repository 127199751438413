import React, { Component } from "react";
import playSound from "../../_shared/data/sounds";
import "./css/StoriesCard.css";

export default class StoriesCard extends Component {
  render() {
    const idName = "storyCard--" + this.props.index;

    return (
      <div
        id={idName}
        onClick={() => {
          if (this.props.isReleased) {
            if (!this.props.isMuted) playSound("open");
            this.props.loadStory(this.props._id)();
          }
        }}
        className={
          this.props.isReleased
            ? "storyCard storyCardContainer"
            : "storyCard storyCardContainer grey"
        }
        style={this.props.style}
      >
        <div>
          <div className="readNowMiddleHover">
            {this.props.isReleased ? "Read Story" : "Coming Soon"}
          </div>
          <img
            className="storyCard--image"
            alt={this.props.title}
            src={this.props.cardImage}
            draggable={false}
          />
        </div>

        <div className="storyCard--text">
          <div className="storyCard--type">
            {this.props.champion.toUpperCase().replace("_", " ")}
          </div>
          <div className="storyCard--title">{this.props.title}</div>
        </div>
      </div>
    );
  }
}
