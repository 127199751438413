import React, { Component } from "react";
import Tooltip from "rc-tooltip";
import "react-toggle/style.css";
import "rc-tooltip/assets/bootstrap_white.css";
import "./css/SideBar.css";

export default class SideBar extends Component {
  state = {};

  itemFunc = (item, index) => {
    const itemWidth = this.props.isMobile
      ? this.props.height - 15
      : this.props.width - 20;
    if (item.paths.find(item => window.location.pathname.includes(item))) {
      if ((this.props.isMobile && item.onMobile) || !this.props.isMobile) {
        return (
          <div
            className="sidebar-item"
            key={index}
            style={{ width: itemWidth }}
          >
            <Tooltip
              placement={this.props.isMobile ? "bottom" : "right"}
              trigger={["hover"]}
              overlay={<span>{item.tooltip}</span>}
            >
              <img
                src={item.image}
                alt={item.alt}
                draggable="false"
                onClick={item.action}
                style={{ width: itemWidth }}
              />
            </Tooltip>
          </div>
        );
      }
    }
    return null;
  };

  render() {
    let sideBarItems = [
      {
        type: "top",

        alt: "battlerite-website",
        action: () => {
          this.props.history.push("/");
        },
        image:
          "https://cdn.battlerite.com/www3/images/static/lore/map/home.png",
        tooltip: "Home",
        paths: ["/", "/map", "/stories"],
        onMobile: true
      },
      {
        type: "top",
        alt: "map-link",
        action: () => {
          this.props.history.push("/map");
        },
        image: "https://cdn.battlerite.com/www3/images/static/lore/map/map.png",
        tooltip: "World map",
        paths: ["/", "/map", "/stories"],
        onMobile: false
      },

      {
        type: "top",
        alt: "stories-link",
        action: () => {
          this.props.history.push("/stories");
        },
        image:
          "https://cdn.battlerite.com/www3/images/static/lore/map/stories.png",
        tooltip: "Story List",
        paths: ["/", "/map", "/stories"],
        onMobile: true
      },

      // {
      //   type: "bottom",
      //   alt: "settings",
      //   action: this.props.toggleSettingsModal,
      //   image:
      //     "https://cdn.battlerite.com/www3/images/static/lore/map/baseline-settings-20px.svg",
      //   tooltip: "Settings",
      //   paths: ["/", "/map", "/stories"]
      // },

      {
        type: "bottom",
        alt: "sound-controls",
        action: this.props.toggleSounds,
        image: !this.props.isMuted
          ? "https://cdn.battlerite.com/www3/images/static/lore/map/sound_unmuted.png"
          : "https://cdn.battlerite.com/www3/images/static/lore/map/sound_muted.png",
        tooltip: this.props.isMuted ? "Unmute" : "Mute",
        paths: ["/map", "/stories"],
        onMobile: true
      }
    ];
    const topItems = sideBarItems
      .filter(i => i.type === "top")
      .map(this.itemFunc);
    const bottomItems = sideBarItems
      .filter(i => i.type === "bottom")
      .map(this.itemFunc);

    return (
      <div className="sidebar">
        <div
          className="sidebar-items"
          style={
            this.props.isMobile
              ? { height: this.props.height }
              : { width: this.props.width }
          }
        >
          <div className="topItems">{topItems}</div>
          <div className="bottomItems">{bottomItems}</div>
        </div>
      </div>
    );
  }
}
