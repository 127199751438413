const arenas = [
  {
    name: "Blackstone",
    text:
      "Carved out of the mountains in the hearth of Othagur. In ancient times the site for sacrificial rituals but now used as an arena. The pent up energies of its legacy makes it an ideal place for battles. It’s under the control of the Brotherhood of the Night.",
    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Blackstone_Arena.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_01.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_02.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_03.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_04.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_06.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_08.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_09.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_10.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_11.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_13.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/BlackstoneArena_14.jpg"
    ]
  },
  {
    name: "Orman Temple",
    text:
      "An arena located in the wild forests of Silverdeep. It’s built in the garden of an abandoned temple and protected by the Dryads. Formerly lost to the forest, but now alive with the spectacle of arena fighting and an oasis for travellers",
    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Orman_Temple_Arena.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/OrmanTemple_day_01.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/OrmanTemple_day_02.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/OrmanTemple_day_03.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/OrmanTemple_day_04.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/OrmanTemple_day_05.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/OrmanTemple_day_06.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/OrmanTemple_day_08.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/OrmanTemple_day_09.jpg"
    ]
  },
  {
    name: "Dragon Garden",
    text:
      "Located in the centre of the Capital. Built by the current ruling Dragon King in honour of his two sons and statues of the two princes adorn the arena. It’s a beautiful and extravagant arena meant to show of the wealth and power of Quna.",
    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/DragonGarden_01.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/DragonGarden_05.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/DragonGarden_07.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/DragonGarden_08.jpg"
    ]
  },
  {
    name: "Sky Ring",
    text:
      "Sky Ring is built on the floating island at the northern coast of Trolobe, near the capital. This luxurious arena is made of expensive materials. It is surrounded by hundreds of airships, which serve as tribunes for the crowds. This luxurious arena is only available for the Royal Family, the most wealthy citizens and special guests. Fighting on this arena is a privilege and winning a victory here brings great honor.",
    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_01.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_07.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_08.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_09.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_10.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_11.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_12.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_13.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_15.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_18.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_19.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_20.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_21.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/SkyArena_day_23.jpg"
    ]
  },
  {
    name: "Mount Araz",
    text:
      "Built on the dragon graveyard at the edge of Cape Kaal, at the slightly more civilized southern coast. This four hundered year old arena has been the violent stage for thousands of fallen warriors and a proving ground for the greatest of champions. It’s now the largest arena in the Eastern Wastelands and attracts both traders and spectators from all over the region. ",
    artwork: [
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/Mount_Araz_Arena_01.JPG",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/MountAraz_day_01.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/MountAraz_day_02.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/MountAraz_day_03.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/MountAraz_day_04.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/MountAraz_day_05.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/MountAraz_day_06.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/MountAraz_day_08.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/MountAraz_day_09.jpg",
      "https://cdn.battlerite.com/www3/images/static/lore/map/location-images/MountAraz_day_10.jpg"
    ]
  }
];

export default arenas;
