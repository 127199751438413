import React, { Component } from "react";
import Modal from "react-modal";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Grid from "@material-ui/core/Grid";
import Card from "./StoriesCard.js";
import Story from "../../_shared/components/Story";
import stories from "../../_shared/data/stories";
import playSound from "../../_shared/data/sounds";
import gtag from "../../_shared/util/analytics";

import "./css/StoriesApp.css";

export default class StoriesApp extends Component {
  state = {
    storyId: -1,
    isModalOpen: false,
    chaptersWidth: undefined
  };

  updateData = valueName => data => {
    this.setState({ [valueName]: data });
  };

  handleResize = force => {
    if (!this.props.isMobile || force)
      this.setState({
        chaptersWidth: window.innerWidth - this.props.sideBarWidth,
        chaptersHeight: window.innerHeight - this.props.sideBarHeight
      });
  };

  componentDidMount() {
    this.handleResize(true);

    const urlStory = stories.find(story =>
      window.location.pathname.includes(story.champion)
    );
    if (
      urlStory &&
      (urlStory.status !== "preview" && urlStory.status !== "hidden")
    ) {
      this.setState({ storyId: urlStory._id, isModalOpen: true });
    }
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  loadStory = storyId => () => {
    gtag("event", "open_story");
    if (storyId) this.setState({ isModalOpen: true, storyId });
  };

  renderCards() {
    return [...stories]
      .sort((a, b) => (new Date(a.date) > new Date(b.date) ? -1 : 1))
      .filter(a => a.status !== "hidden")
      .map((card, index) => {
        const isReleased =
          card.status === "released" || card.status === "featured";
        const style = {};
        if (index === stories.length - 1) {
          style.marginBottom = "0px";
        }

        return (
          <Card
            index={index}
            key={index}
            _id={card._id}
            title={
              isReleased ? card.content.find(c => c.title).title : "Coming Soon"
            }
            cardImage={card.cardImage}
            loadStory={this.loadStory}
            isMuted={this.props.isMuted}
            champion={card.champion}
            style={style}
            isReleased={isReleased}
            status={card.status}
            date={card.date}
          />
        );
      });
  }

  closeModal = () => {
    if (!this.props.isMuted) {
      playSound("close");
    }
    if (this.props.displaySettings) this.props.toggleSettings();

    this.updateData("isModalOpen")(false);
  };

  render() {
    let story = null;
    let cards = this.renderCards();

    if (this.state.storyId !== -1) {
      story = stories.find(story => story._id === this.state.storyId);
    }

    const featuredCard = cards.filter(c => c.props.status === "featured");
    const previewCards = cards
      .filter(c => c.props.status === "preview")
      .sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));
    const releasedCards = cards
      .filter(c => c.props.status === "released")
      .map((c, i) => (
        <Grid item xs={12} md={6} lg={3} key={i}>
          {c}
        </Grid>
      ));
    return (
      <ReactCSSTransitionGroup
        transitionName="updateFade"
        transitionEnterTimeout={300}
        transitionLeave={false}
        transitionAppear={true}
        transitionAppearTimeout={300}
      >
        <div
          id="stories"
          style={{
            width: this.props.isMobile ? "100vw" : this.state.chaptersWidth,
            height: this.props.isMobile ? "95vh" : "100vh"
          }}
        >
          <div id="stories--content">
            <div id="stories--landing">
              <div id="stories--landing--big">{featuredCard[0]}</div>
              <div id="stories--landing--small">{previewCards}</div>
            </div>

            <div id="stories--cards">
              <Grid container spacing={24}>
                {releasedCards}
              </Grid>
            </div>
          </div>

          <Modal
            isOpen={this.state.isModalOpen}
            onRequestClose={this.closeModal}
            style={{
              content: {
                top: this.props.isMobile ? this.props.sideBarHeight : "-3px",
                left: this.props.isMobile
                  ? "50%"
                  : this.state.chaptersWidth / 2 + this.props.sideBarWidth,
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, 0%)",
                width: this.props.isMobile ? "80%" : "70%",
                height: this.props.isMobile
                  ? this.state.chaptersHeight - 8
                  : "100vh",
                padding: "0px",
                color: "rgb(231, 230, 230)",
                filter:
                  "progid:DXImageTransform.Microsoft.gradient( startColorstr='#969392', endColorstr='#8a8685', GradientType=1) ",
                border: "#d3fdfd double 3px",
                overflow: "none"
              },
              overlay: {
                backgroundColor: "#001319cb"
              }
            }}
            ariaHideApp={false}
          >
            <Story
              story={story}
              closeModal={this.closeModal}
              settings={this.props.settings}
              toggleSettings={this.props.toggleSettings}
              updateSettings={this.props.updateSettings}
              displaySettings={this.props.displaySettings}
              resetSettings={this.props.resetSettings}
            />
          </Modal>
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}
