import React, { Component } from "react";
import Map from "./Map";
import Story from "../../_shared/components/Story";
import pins from "../../_shared/data/pins";
import stories from "../../_shared/data/stories";

import playSound from "../../_shared/data/sounds";
import Modal from "react-modal";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import { betweenRange } from "../util/compareFunc";

import "./css/MapApp.css";

export default class MapApp extends Component {
  constructor() {
    super();
    this.state = {
      pins: [],
      mapHeight: undefined,
      mapWidth: undefined,
      storyId: 0,
      isModalOpen: false
    };
  }

  updateData = valueName => data => {
    this.setState({ [valueName]: data });
  };

  loadStory = storyId => () => {
    if (storyId) this.setState({ isModalOpen: true, storyId });
  };

  handleResize = () => {
    this.setState({
      mapHeight: window.innerHeight,
      mapWidth: window.innerWidth - this.props.sideBarWidth
    });
  };

  componentDidMount() {
    this.handleResize();
    // pins.push(this.generateRandomPinLocation("oldur"));
    // pins.push(this.generateRandomPinLocation("jamila"));
    this.setState({
      pins
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  generateRandomPinLocation = championName => {
    const x = Math.round(Math.random() * 4401) + 150;
    const y = Math.round(Math.random() * 3372) + 150;
    if ((x < 1631 && y > 2750) || x > 4201 || y > 3172) {
      return this.generateRandomPinLocation(championName);
    }
    for (let i = 0; i < pins.length; i++) {
      const currentPin = pins[i];

      if (
        betweenRange(x, currentPin.x, 200) &&
        betweenRange(y, currentPin.y, 200)
      ) {
        return this.generateRandomPinLocation(championName);
      }
    }
    return {
      x,
      y,
      champion: championName
    };
  };

  closeModal = () => {
    if (!this.props.isMuted) {
      playSound("close");
    }
    if (this.props.displaySettings) this.props.toggleSettings();
    this.updateData("isModalOpen")(false);
  };

  render() {
    let story = null;

    if (this.state.storyId !== -1) {
      story = stories.find(story => story._id === this.state.storyId);
    }

    return (
      <div>
        <ReactCSSTransitionGroup
          transitionName="updateFade"
          transitionEnterTimeout={300}
          transitionLeave={false}
          transitionAppear={true}
          transitionAppearTimeout={300}
        >
          <Map
            pins={this.state.pins}
            stories={stories}
            updatePins={this.updateData("pins")}
            loadStory={this.loadStory}
            key={1}
            sounds={this.audioElements}
            isMuted={this.props.isMuted}
            sideBarWidth={this.props.sideBarWidth}
          />
        </ReactCSSTransitionGroup>

        <Modal
          closeTimeoutMS={150}
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          style={{
            content: {
              top: "-3px",
              left: this.state.mapWidth / 2 + this.props.sideBarWidth,
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, 0%)",
              width: "70%",
              height: "100%",
              padding: "0px",
              color: "rgb(231, 230, 230)",
              filter:
                "progid:DXImageTransform.Microsoft.gradient(startColorstr='#969392', endColorstr='#8a8685', GradientType=1)",
              border: "#d3fdfd double 3px",
              overflow: "none"
            },
            overlay: {
              backgroundColor: "#001319cb"
            }
          }}
          ariaHideApp={false}
        >
          <Story
            story={story}
            closeModal={this.closeModal}
            settings={this.props.settings}
            toggleSettings={this.props.toggleSettings}
            displaySettings={this.props.displaySettings}
            updateSettings={this.props.updateSettings}
            resetSettings={this.props.resetSettings}
          />
        </Modal>
      </div>
    );
  }
}
