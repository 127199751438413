const content = [
  {
    title: `Othagur Rises`
  },
  {
    text: `To Our Highlord Pestilus, Executor of Rasal, Purveyor of Bile and Blood,`
  },
  {
    text: `It is as you predicted, Lord Pestilus, all things transpired according to your plan and the will of Rasal.
 `
  },
  {
    text: `Not long after you departed to the Wyrm's realm of Quna, the wretched abode of self-appointed kings, we made the most enlightening discovery. One fateful night when blood swells were unusually agitated a most curious creature washed up on the black shore of Othagur.`
  },
  {
    text: `There he was: barnacle-infested, seaweed bedraggled, a shriveled shadow of his former self. The god we of Othagur had once worshipped for his might, cunning, and mercilessness... found half-drowned, spat forth from the sea he once commanded.`
  },
  {
    text: `And now Tiermok the Pale, now Tiermok the Drowned, God of the Sea and the Abyss, is Othagur's slave.`
  },
  {
    text: `It is as you foresaw, Lord Pestilus. But that is not all.`
  },
  {
    text: `Bloodpriest Faminius was struck with the most brilliant of ideas. What if we found a way to siphon the fallen god's remaining power? He could be a living source of energy. And as you know, great lord, the secret of all things lay waiting in blood.`
  },
  {
    text: `Faminius hooked Tiermok to one of his famed Blood Engines and found his speculations were well founded. The Drowned God's blood was power itself. We used this blood and transfused it into our greatest warriors.`
  },
  {
    text: `Angorath the Reaver was the first to taste the blood of Tiermok. Already a force to be reckoned with, the blood transformed him into a mountain among men. A dark, savage demon of battle. He eagerly awaits your orders.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/pestilus/how_to_make_a_boi.jpg`
  },
  {
    text: `But Othagur's greatest accomplishment and one you will be most proud of was soon to come after. Faminius reasoned that, if we could use the blood of a god to empower men, we could also use this blood to strengthen a god.`
  },
  {
    text: `And so we fed Rasal, God of Insects and Slithering Things, deep in his dark hole, the blood of Tiermok.`
  },
  {
    text: `And my Lord Pestilus, he has grown into something beyond monstrosity. Beyond anything even you could have foreseen. Rasal is no longer a mere master of insects, but of the Abyss itself. While you hold Quna in your rotted grip, the power of Othagur waxes to an unholy zenith.`
  },
  {
    text: `While Quna falls, Othagur rises.
     `
  },
  {
    text: `It is now only a matter of time.
     `
  },
  {
    text: `In Blood and Bile,
     `
  },
  {
    text: `Bloodpriest Hakkan
     `
  }
];

export default content;
