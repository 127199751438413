const sounds = {
  hover: new Audio(
    "https://cdn.battlerite.com/www3/images/static/lore/shared/sounds/UI_Hover_v3_3.wav"
  ),
  click: new Audio(
    "https://cdn.battlerite.com/www3/images/static/lore/shared/sounds/UI_Hover_v3_1.wav"
  ),
  open: new Audio(
    "https://cdn.battlerite.com/www3/images/static/lore/shared/sounds/UI_HoverBRCard.wav"
  ),
  close: new Audio(
    "https://cdn.battlerite.com/www3/images/static/lore/shared/sounds/UI_HoverBRCardReversed.wav"
  ),
  pearl1: new Audio(
    "https://cdn.battlerite.com/www3/images/static/lore/shared/sounds/dont.wav"
  ),
  pearl2: new Audio(
    "https://cdn.battlerite.com/www3/images/static/lore/shared/sounds/worry.wav"
  ),
  pearl3: new Audio(
    "https://cdn.battlerite.com/www3/images/static/lore/shared/sounds/im.wav"
  ),
  pearl4: new Audio(
    "https://cdn.battlerite.com/www3/images/static/lore/shared/sounds/here.wav"
  ),
  pearl5: new Audio(
    "https://cdn.battlerite.com/www3/images/static/lore/shared/sounds/now.wav"
  )
};
Object.keys(sounds).forEach(e => (sounds[e].volume = 0.1));

const playSound = sound => {
  // Object.keys(sounds).forEach(e => {
  //   if (e !== sound) sounds[e].pause();
  // });
  sounds[sound].load();
  sounds[sound].play();
};

export default playSound;
