function convertTextBlock(input) {
  if (!input.text.includes('"')) return input;
  const blocks = [];
  const inputText = input.text;

  let lastIndex = 0;

  let currentlyInQuote = inputText[0] === '"';

  while (lastIndex < inputText.length - 1) {
    let quoteIndex = inputText.indexOf('"', lastIndex + 1);
    if (quoteIndex === -1) quoteIndex = inputText.length;
    if (currentlyInQuote) quoteIndex++;

    let block = {};
    lastIndex = lastIndex - 1 >= 0 ? lastIndex : 0;
    block = inputText.substr(lastIndex, quoteIndex - lastIndex);
    lastIndex = quoteIndex;
    if (block) {
      let style = {};
      if (input.style) {
        input.style.fontStyle = "italic";
        style = input.style;
      }

      style.fontStyle = "italic";
      blocks.push({
        text: block,
        style: currentlyInQuote ? style : null
      });
      currentlyInQuote = !currentlyInQuote;
    }
  }

  return {
    italicizedBlocks: blocks
  };
}
function convertContent(content) {
  for (let i = 0; i < content.length; i++) {
    if (content[i].text) content[i] = convertTextBlock(content[i]);
  }
  return content;
}

function convertStories(stories) {
  return stories.map(s => {
    s.content = convertContent(s.content);
    return s;
  });
}

module.exports = {
  convertContent,
  convertStories,
  convertTextBlock
};
