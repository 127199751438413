const content = [
  {
    title: "The Alchemy of Dreams"
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/lucie/illiterate_girl_drawing_lines.jpg`
  },
  {
    text: `Excerpts from Lucie Darrow’s Diary.`,
    style: {
      textAlign: "center"
    }
  },
  {
    text: `Diary Entry 1`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `Mother got this diary for me, so I figured I might as well put in my first entry. Today is my 13th birthday! I'm finally beginning to feel less like a child. I'm practically an adult! I don't quite feel older than I did yesterday, but something has to have changed. The air feels electric, just alive with possibility!`
  },
  {
    text: `Mother and Father planned a surprise party for me, even though it wasn't much of a surprise. They didn’t hide the balloons very well. But that’s fine, it was a blast! All my friends from school were there, even that prissy princess, Matilda Pineorchard. She pouted and cried all day just because she didn't get the biggest slice of cake. Can you believe it? She's such a spoilt brat.`
  },
  {
    text: `If Zander was there, maybe he could have done a magic trick and made her disappear, that would teach her! But Zander never showed up! He was supposed to come home from visiting his circus friends downtown hours ago. Well, you know Zander. Like Mother says, "Zander is like a black hole, all time and space gets lost wherever he goes." Spoken like a true Astronomy professor `
  },
  {
    text: `I hope Zander gets home soon, I can't wait to show him the new Lilith’s Cauldron Alchemy kit Father bought for me. It has a gilded crucible and everything! Now nothing will stop me from being the best Alchemist who ever lived `
  },
  {
    text: `Diary Entry 155`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `Today was the most wretchedly amazing day of my life! It’s going to be a LONG entry. Where do I start? Well, Professor Noshlin proved once again to be more clueless than I thought humanly possible. Today’s assignment was a simple transmutation, turning lead into iron`
  },
  {
    text: `Matilda Pineorchard and her gang of nitwit gas-heads were of course giggling and carrying on without any care in the world. Matilda’s father is the Dean of the school, so she doesn’t have to worry about passing Professor Noshlin’s class - but I do.`
  },
  {
    text: `I opened my copy of "An Adept’s Adventure into Alchemy" and turned to page 111. The book is large, leather-bound, and worn with the use of probably hundreds of students before me. But it smells like sulfur, iron, and charcoal, and that’s lovely. But what surprised me was what was hidden on that page. A playing card! An ace of hearts - a message from Zander `
  },
  {
    text: `He’d always had a deck of cards with him, doing his magic tricks and sleight of hand. But I hadn't heard from him in three years! How would he have known I’d turn to this page? How’d he even get my book? I turned the card around and three words were written on it, "Star Gazing. Tonight." It WAS Zander. We’d always sneak out late at night and watch the stars on the roof of our house when we were younger `
  },
  {
    text: `Ah, I really missed those days. I really missed Zander. And just then as was I lost in my thoughts, Professor Noshlin slammed his book onto my desk. "Lucie Darrow, are you paying attention!?" He squawked. I quickly hid the card. His breath reeked of eggs and garlic like always. Yeck! I nodded and tried to hold back my lunch. Then he had the nerve to tell me that maybe if my mind wasn’t always in the clouds I’d be at the top of my class instead of dawdling at the bottom! `
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/lucie/asdanfkjnweljbglsdfbgsel.jpg`
  },
  {
    text: `It’s not my fault his class is so comically boring that I fall asleep every day. And of course, Matilda and her googly-eyed goons giggled and laughed and pointed at me. I’ll show them. I’ll show all of them. I’ll be the best Alchemist this Academy had ever seen. Who’ll be laughing then?`
  },
  {
    text: `Later that night I was awake in bed studying one of the books I’d stolen from the Library. A secret tome on forbidden alchemy. I really don’t know why it was "forbidden." I think the stuffy old professors are just afraid of innovation. But I won’t let that stop me. This chapter was about using the art of distillation to extract essence locked in obsidian, used to create Oil of Shadows, which can be used in making objects disappear. Too cool!!      `
  },
  {
    text: `Anyway, while I was reading, I heard a strange scuffling sound above me. Could it be him? I climbed out of the window and scampered up the drainpipe to the roof like I’d done so many times when I was a kid. What I saw next was like something out of a dream. `
  },
  {
    text: `There he was, silhouetted against the moon. He was taller than I remember and wore a dark cloak that seemed to move in invisible winds. His emerald eyes gleamed from under the shadow of his flaxen bangs. I rushed to him and hugged him with all my might. Zander was back! I looked up and he smiled at me in that cat-like way that only Zander could. Every fiber of his being was in that smile.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/lucie/uwahhhhh_so_kawaiiiiiiiiii.jpg`
  },
  {
    text: `I wiped away a tear. "Where have you been?’" I asked. "It’s been over three years, Zan! I’ve been worried sick! We all have been. You know-"      `
  },
  {
    text: `He hushed me, "I’ve missed you so much, little sis." he said, his voice low and steady. "I know, I know, it was cruel of me to leave without a word. But you know Mother, I could never have left any other way. I didn’t want to fight or argue whether being a magician was worthy of the Darrow name."`
  },
  {
    text: `I knew he was right. Mother would never have allowed him to pursue his dream of being a magician without some rue. That didn’t make it hurt any less though. "Well, where have you been all this time, what have you done? Did you become a magician?" I asked.`
  },
  {
    text: `Zander smiled at me again, "Yes, yes. I’ve traveled the world and seen many wonders. Things you wouldn’t believe. But I think I’ve found something that made leaving for so long worth it. Real magic. Not just parlor tricks, not just pulling rabbits out of hats or sleight of hand. I mean REAL magic, Lucie. It’s right at my fingertips. I’m almost there."`
  },
  {
    text: `Zander looked distant then like his mind was somewhere far away.`
  },
  {
    text: `"What do you mean, Zan? REAL magic… how?" I asked. He stared at me a long time. Kissed me on my forehead.`
  },
  {
    text: `"That’s why I came here," he said, "I’m going to do something very foolish. But if it works out, I’ll be the greatest magician who ever lived." He turned around then, his back to me, gazed up at the curtain of stars above us. "Promise to tell Mother and Father I love them. And no matter what happens, not to think ill of me."`
  },
  {
    text: `Zander turned back to me, those green eyes of his somewhat dimmed. "You promise?" he whispered. I nodded back, "I promise, Zander. But do be careful. Please, I don’t know what I’d do if something were to happen to you." Zander just smiled that smile of his, "I have to go now. But I’ll be back soon, don’t you worry. I’ll miss you dearly. Oh, and one last thing." Zander stepped close and hugged me tight again then looked at me.`
  },
  {
    text: `"Remember Lucie, always keep your mind in the clouds, that is the only way for your thoughts to soar above everyone else's." And with that, he turned in a dramatic swirl of cloak, bowed to me, and leaped off of the roof! I ran after him and looked over the edge.`
  },
  {
    text: `He was gone. I sighed, breathed in the cool midnight air. I looked up into the night sky. The stars were alive with endless possibility.`
  },
  {
    text: `Diary Entry 157`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `Tried the obsidian distillation formula, the process seemed simple enough, but I almost blew off the ceiling of the shed I used as my lab! Need to find an opportunity to try again... Was the mercury I used impure? Oh well, back to the drawing board!  `
  },
  {
    text: `Diary Entry 188`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `Well, shoot! Got caught today "borrowing" some ingredients for the petrification potion I found in THE book. Death Root and Blue Sulphur. Managed to come up with a somewhat believable excuse and got off with a scolding that could have made one's ears fall off. Gotta be more careful. Maybe that Oil of Shadows might come in handy...`
  },
  {
    text: `Diary Entry 202`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `I don’t know how, but he’s done it. He really did it. Zander’s actually did find REAL magic! He joined this troupe of performers called the Phantomime Masquerade (so mysterious!) and he’s starting to get famous all over Toleen. Toleenians are often ridiculed for being the only human culture that possesses no magic in religion or academia. So Zander doing real magic is unprecedented! He’s on the front page of every newspaper! He’s so inspirational and dashing! I managed to steal away tonight with a few friends and go to his show.`
  },
  {
    text: `It was amazing, breathtaking even. There were knife jugglers, flame-breathing acrobats, lion tamers, it was all wonderful... but Zander… Zander was different. There was a coldness there. He seemed distant. And one of his eyes, it glowed with a strange purple energy? I waved to him as he looked at the crowd and… it was as if he didn’t recognize me. I tried to meet with him backstage but they said he wasn’t seeing visitors. I’m so happy Zander found real magic… but I wonder at what cost.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/lucie/very_chipmunk_much_amaze.jpg`
  },
  {
    text: `Diary Entry 398`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `No, it can’t be true! It’s on the front page all of the newspapers: "The Spectacular and Mystical Phantomime Masquerade Attacked! 12 Performers Dead, the Rest Vanished Without a Trace!" People everywhere are buzzing about it. There’s even a rumour that Zander made a pact with a Blood Spirit and it came to reap his due. The paper said they were all killed by… some unknown force or spirit. But Zander would never make a pact with a Blood Spirit… would he? Zander’s body was never found. Maybe he is fine. Oh, Zander please come home and let us know you are alright. I won’t believe you are dead. I won’t!  `
  },
  {
    text: `Diary Entry 574`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `I guess I’ve been found out.`
  },
  {
    text: `Matilda and her goons found my hidden stash of books I stole from the library in my secret hiding place under the mangrove tree. Before I knew it, I found myself in the Dean’s office with my parents, and was expelled. The Dean didn’t want to hear my side. Didn’t want to see what I’ve found out with my experimental alchemy. He just wanted me gone.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/lucie/gurl_dem_eyelashes_are_fire.jpg`
  },
  {
    text: `Mother and Father expressed how disappointed in me they were. I didn’t argue or protest. They were right, I failed them. I failed myself. If Zander was here, I knew he wouldn’t approve. It’s been two years since I lost him. But Zander chased his dreams and made them come true even if he did… end up… dead. He became the greatest magician in the world. He made his dreams real. What about my dreams of becoming the great Alchemist in the world... I didn't know if I'd ever make them real.`
  },
  {
    text: `But… as I was walking home, I saw a flyer.`
  },
  {
    text: `"Enter the Arenas, Champions wanted. All manner of Man, Beast, and Others are Welcome. Fight for Honor, or for Gold. Sign up today!" Reluctantly I took the flyer and walked home. Later, I heard scuffling on the roof again! It couldn’t be him, could it? That would be impossible. I darted out of my bed and quickly climbed out of the window and onto the roof to see…`
  },
  {
    text: `No one. No one was there. The cold wind blew and howled, and I was alone. I turned to climb back down and noticed something on the cobblestone roof. A small piece of paper. A card? On the front was an Ace of Hearts and on the back… a drawing of a cloud.`
  },
  {
    text: `It was Zander! Somehow he is alive! And the cloud... reminded me what he once told me. "Remember Lucie, always keep your mind in the clouds, that is the only way for your thoughts to soar above everyone else's."`
  },
  {
    text: `I won’t let anything stop my dreams from becoming a reality. I'll use the Arenas as my classroom. In the Arenas, no stuffy professors or prissy princesses would get in my way, and if they do… I’ll be ready!`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/lucie/they_are_some_big_hecking_gloves.jpg`
  }
];

export default content;
