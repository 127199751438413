const content = [
  {
    title: "A Soldier's Tale"
  },
  {
    text: `"Grandpa Bakko! Grandpa Bakko! Is it true? Are you going to leave town?", my grandchildren called out as I threw some freshly chopped logs into the fireplace.`
  },
  {
    text: `"Ah, so you’ve heard I take it? Yes, I am setting out to partake in a tournament where brave warriors come from around the world to compete for pride and glory!" I turned around and sat with my back towards the fire, a smile forming on my face as I recalled my memories. "In the midst of my past battles, my heart would burn as hot as that fire," I told my grandchildren.
    `
  },
  {
    text: `"Wow Gramps, were you really a warrior?", Jilldrie asked just before Isan peeped up: "Ya gramps, tell us a story!"`
  },
  {
    text: `"Ah well if it’s a story you want, then a story you shall get my grandchildren," I said with a smile as I scratched my chin. "Have I ever told you about Boulder Pass?" The little ones looked at me with wide eyes and shook their heads no.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/bakko/bakko_and_fam.jpg`
  },
  {
    text: `"Long ago, when your mother was only a child, just about twice your age, Jilldrie, I was indeed a warrior, but not just a soldier among many soldiers. I was their leader, and I was proud of every one of those brave men..."`
  },
  {
    linebreak: true
  },
  {
    text: `Our base was located at the edge of the Na’Gol Mountains, just south-west of the Frozen North. It was our duty to guard Boulder Pass from the Ice Giants of the north. The Frozen Queen was greedy for territory and wanted to spread her icy reach into the Ga’Tamang Empire. We were here to prevent just that from happening.`
  },
  {
    text: `The day of the great battle was like any ordinary day, perhaps a slight chill in the wind if anything at all. The soldiers and I had just woken up, and we were having our morning breakfast before the daily combat drills would commence. A low, distant rumbling reverberated through the base as we were sharing stories about our families and loved ones over eggs and sausages. `
  },
  {
    text: `The rumbling grew even louder, so I decided to investigate. With a sausage link still in my mouth, I climbed up the watchtower and peered out into the distance. Hundreds of giants barrelling down at us from the mountains. I peered through my spyglass to get a closer look at them as I finished up chewing my meal. I knew that I was going to need all the energy that I could get as I saw the Frozen Queen herself, riding above her army on a frozen wind.
`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/bakko/nice.jpg`
  },
  {
    text: `I ran back as fast as my feet could carry me and shouted to my men: "Breakfast is over, we have a battle on our hands. The Ice Giants are coming!" Everyone hastily gulped down their drinks and swallowed their last morsel of food before they all ran to the weapons racks to arm themselves. I, however, always had my axe and shield at the ready`
  },
  {
    text: `Within moments, the Ice Giants bore down on us, all howling their crazed warcry. But we were the Vanguard, the defenders of Ga’Tamang, and we wouldn't let the savage monsters intimidate us. We responded with our own mighty warcry as we charged right into the fray.
    `
  },
  {
    text: `The Giants were colossal and full of fury, their eyes blazing with ice-blue fire. Standing upright, they were as tall as two grown men and just as wide. They were covered in thick white fur to protect them from the cold of the mountains and their skin was as hard as chainmail. They were a worthy foe indeed. My brethren may not have been as strong individually, but we made up for that with bravery and discipline, numbers, and strategy. `
  },
  {
    text: `We had built up barriers to funnel them in, no more than a few Giants at a time could pass. We also had archers on the towers using flaming arrows. The first few Giants fell quick, covered in arrows like oversized pincushions.
    `
  },
  {
    text: `The Giants seemed to be more intelligent than usual as they were now targeting our barricades and archers. The Giants threw boulders the size of cows with the velocity of cannon blasts! Giants were usually a slow and cumbersome brood, but now they were actually using strategy, not just mindlessly smashing. Very peculiar, I thought.`
  },
  {
    text: `"Abandon the walls!" I yelled, knowing that the wooden structures would not hold out much longer against the onslaught. Not a minute had passed since I gave that order and the barricade was obliterated to kindle.
    `
  },
  {
    text: `Now the real battle began. The Giants rushed in all at once. It took at least three men to fell one of them, not an easy task by any means. A giant swung its massive club at my head, I deflected with my shield and felled him with my axe all in one swing.  
    `
  },
  {
    text: `A Giant among Giants crashed in front of me, causing the earth itself to tremor. This was no ordinary Ice Giant. He was two or three times bigger than all the others, and the beast was wielding a double-bladed axe in each hand, shimmering with magical frost. I had heard of this berserker among the giants. His name was Gormung the Breaker.
    `
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/bakko/bakko_and_goliath.jpg`
  },
  {
    text: `As I struck, the air froze solid in front of me. My axe clattered uselessly against the ice barrier. I looked behind to see the Frozen Queen herself, hovering on a vortex of frozen wind, hand raised in a magic gesture. I dove out of the way, as Gormung's axe barely missed me. It would only take one direct hit and I would be flying through the air in bloody chunks.  `
  },

  {
    text: `I knew that I needed to lure Gormung from the Ice Queen to stand a chance. On a hunch, I turned away from the giant berserker and started running and shouting. Looking back from the corner of my eye, Gormung was confused at first but then barrelled towards me, just as I had hoped. The monster just couldn't resist chasing after supposed prey.`
  },
  {
    text: `Gormung hurled one of his axes after me with thunderous velocity. I dove to the ground; the axe landed with an earth-crunching thud next to me. Then an idea began to take shape in my mind. As Gormung stormed closer, he swung his other axe at my head. I ducked, pivoted, spun and slammed my axe as hard as I could into his knee. Gormung howled bloody murder as his leg gave out from under him. His cries of agony stopped short. He fell face-first onto his axe that stuck out of the ground, cleaving his head in two.
    `
  },
  {
    text: `I took a second to catch my breath as I leaned against my shield and surveyed the battlefield. My men were fighting bravely, but they were being pushed back against a tide of Ice Giants. I advanced forward, three of the ice giants leaped at me at once. I dashed forward, blocking a blow from one Ice Giant on my shield and slashed upward, dispatching him. I sprang towards the other two. I dodged a wild haymaker from one giant and impaled him on the horns on my shield, then flung my axe into the other Giant's throat.
    `
  },
  {
    text: `I yanked my axe out of the Giant's neck and found myself face to face with the Frost Queen herself. She did not look pleased, I'll tell you that!
    `
  },
  {
    text: `Her skin was a pale, translucent blue. Her form was human, but her limbs were elongated and spindly. She wore a dark gown collared in black fur. Her large, otherworldly eyes blazed like frozen stars, oddly similar to eyes of the giants. Her features were delicate, beautiful if not alien.
    `
  },
  {
    text: `She spoke in a whisper of a language I'd never heard before. Glowing runes spilled out of her mouth, some kind of mantra or spell. The runes transformed into jagged blades of ice and hurled towards me. I blocked with my shield, but it left it all but frozen solid.
    `
  },
  {
    text: `The Frozen Queen suddenly lifted her arms high above her, chanting some ancient spell. I held my axe tight, looking around cautiously. The air started to freeze. Giant walls of ice appeared from nowhere and surrounded me.
    `
  },
  {
    text: `The walls formed a sort of maze, concealing the queen from me. I looked up with my shield and weapon raised, searching for the frigid Queen. Suddenly, bolts of ice ejected out of the walls. I ducked fast and used my shield to block, but some of the ice spikes slashed my legs and arms. Thankfully it was only a graze, but it definitely got my attention. One mistake against this Queen could be my last.
    `
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/bakko/bakko4.jpg`
  },
  {
    text: `I pressed on, weaving and dashing between the walls, dodging the occasional ice arrow or icicle trap. I turned a corner and walked into a dead end. I needed to get out of this maze, but how? She was trying to tire me out, break my spirit.
    `
  },

  {
    text: `But I didn't intend on letting that happen. I may not have been the sharpest axe in armory, but by the Ancestors I was the biggest and the hardiest. I gathered my will and ran at the ice wall with all my might. I let out a fearsome battle cry that surely echoed all through Boulder Pass as I shattered the ice wall.
    `
  },

  {
    text: `Finally free of the frozen labyrinth, I scanned the battlefield. The Giants were slowly breaking through my men. I needed to act quickly.`
  },
  {
    text: `An idea struck me. It was a gamble, but one I had to take, one I had to win. I started to carefully make my way close to the Queen. I knew she was aware of where I was, but she pretended not to be. She was luring me in. Perfect for my plan. I then leaped into the air towards her. She quickly turned to face me, with a grin that meant trouble. Little did she know, I had a bit of trouble of my own.
    `
  },
  {
    text: `At the last moment, she thrust her hands at me and out shot a massive spear of ice. I raised my shield against her. And this wasn’t just any slab of wood, but a Vanguard’s shield, blessed with the war-magic of our Ancestors of old. As the spear struck, the shield flared up and with a bright flash reflected the projectile back towards the queen.
`
  },
  {
    text: `The look on her face was priceless. She futilely tried to cover herself with her hands, but the spear of ice impaled her, freezing her body solid. She didn't even have time to scream. I struck her frozen form with my axe, shattering her into a thousand pieces.
    `
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/bakko/u_did_it.jpg`
  },
  {
    text: `Something peculiar happened when the Queen died. All the remaining Giants stopped fighting. The blue fire in their eyes faded to a peaceful brown, and they had the most confused look on their faces. The Giants looked dazed for a moment, dropped their arms and left the way they came from, not interested in anything other than going back home.
    `
  },
  {
    text: `It seems the Queen was controlling them against their will this entire time. I only wish I could have gotten to her sooner so neither these Giants nor my men would have died. The Ice Queen was gone, and there was no need for more bloodshed. We all gathered our fallen comrades and held a funeral ceremony followed by a traditional, post-battle feast.
    `
  },
  {
    linebreak: true
  },
  {
    text: `"And that was the story of the Battle of Boulder Pass, my grandchildren," I told them as i chewed the rest of the turkey leg. The two had enormous smiles on their faces as they sat cuddled up, a sparkle of excitement and awe gleaming from their eyes. "That was the best story ever Grandpaaa!" the both of them cried out.`
  },
  {
    text: `"Oh, it was more than just a story, it was a part of my life and a part of history. I miss that feeling of accomplishment, the rush of adrenaline, the competition of a worthy foe. I miss challenging myself; this is why I must fight in the Arena. Fighting is a part of me. It's what forged me into who I am today. Do you understand now why I must go?", I smiled. Both of them nodded, "We get it, Grandpa Bakko, go out there and kick some butt and bring back a prize!" little Jilldrie exclaimed.
    `
  },
  {
    text: `And with that, I gave them both a long warm hug and set out the door to the Arenas, where battle and glory was ripe for the taking.
    `
  },
  {
    text: `It has been far too long and I am itching for a fight.`
  }
];
export default content;
