const content = [
  {
    title: "The Covenant and the Heir"
  },
  {
    text: `"No opponent is more powerful than the wind," Shifu, the wizened, white-haired spearmaster, screamed to his ward. "The Way of the Spear means submitting to the elements!" Shifu stood on the side of the arena floor watching his heir to the mysterious and mystical way of the Ni’Shu fight to the death. He hoped he would live.`
  },
  {
    text: `"I’m trying, Master!" The Heir replied, gripping the spear in defense.`
  },

  {
    text: `The Heir’s spear blade missed the face of the Challenger. The Challenger did not miss. The handle of his battle-axe struck the Heir’s face, opening a vicious cut. Shifu’s eyes widened, his heart dropped. The Challenger spun around with neck-snapping speed, slashing the blade of his axe through the Heir’s lower torso.`
  },
  {
    text: `The Heir ran his hand across his stomach and winced at the sight of his own blood bursting out in foamy spurts. His eyes darted frantically around the arena until he found Shifu, who was witnessing the pain and shock of a boy unprepared for death. The spear fell from the Heir’s hands, and he collapsed with it.`
  },
  {
    text: `Shifu burst into the arena and rushed over to the boy to pick him up, but the body was already limp. He looked into the Heir’s open, lifeless eyes. Shifu’s Heir, the apprentice who was to continue the ancient Way of Spear, was slain.`
  },
  {
    text: `Shifu kneeled to whisper to his fallen ward, "You stubborn boy. I told you the arena is no place for experimenting." A tear fell from Shifu's eye, hit the ground, and was reclaimed by the earth.`
  },
  {
    text: `Only in the boy’s death did Shifu finally recognize him as a man, absent of innocence. It reminded Shifu of the moment his own childhood eyes darkened, his carefree spirit exorcized.`
  },
  {
    text: `For most of his adult life Shifu had buried this memory - the day he was cursed by his family's heritage. But today, with knees soaked with the blood of his Heir, he had no choice but to remember.`
  },
  {
    linebreak: true
  },
  {
    text: `Shifu cried in the hem of his sister’s skirt, hiding his eyes from the madness around him.`
  },
  {
    text: `They were in the warmth of his Grandfather’s cottage, but he felt as cold as a winter morning. His Grandfather scolded him, "Tears mean nothing to the Ashen One. Gather your strength, boy." Looking up at his Grandfather's weary yet mournful eyes, Shifu tried his best to be strong, but he was only a boy and the tears flowed freely. The body of his dead mother lay at his feet.`
  },
  {
    text: `A tawny shadow stood over her, slowly taking the shape of a craven, dark figure. Its face was full of lines, cracked and hard like the bark of a tree. It stood tall like a broken castle tower, upright and folded over. It wore a cloud of ash like a cloak. It wasn’t a man or beast, demon or angel, but made of wind and dust and menace.`
  },
  {
    text: `At the Ashen One's clawed, bare feet, Shifu’s Grandfather, draped in tattered clothing, kneeled in obeisance. Young Shifu looked on and held his sister tighter while she stroked his hair and caressed his cheek reassuringly.`
  },
  {
    text: `"Lord of Earth and Air, you didn’t have to kill her," The Grandfather cried.`
  },
  {
    text: `"Disobedience comes at a cost. A life. This is our covenant. Our price. Present your Heir." The Ashen One's low voice was like the grinding of rock against rock, an autumnal gale whipping through a forest of oak.`
  },
  {
    text: `The Ashen One wrapped its gnarled, tendriled fingers around the neck of his Grandfather, lifting the old man several feet in the air.`
  },
  {
    text: `Shifu stared at his Grandfather’s bare and soiled feet, scabrous from decades in the field, dangle in the air. He thought to himself, "Grandfather. Please. Just submit." Eyes bulged, his Grandfather kicked feverishly as he gasped for air.`
  },
  {
    text: `The Ashen One continued without haste, "You hid your eyes from me, and as the law reads, you must select an Heir of The Way. If you have no Heir, a life must be taken. Where is your Heir?"`
  },
  {
    text: `"He’s…" Barely able to breathe, Grandfather struggled to stay conscious. The Ashen One dropped him to the ground. The Grandfather coughed for air, "He’s dead. Sickness, my lord."`
  },
  {
    text: `"So he has been delivered to the gods. And what of his son? What else would a man live for, but to pass the Way on to his offspring."`
  },
  {
    text: `"But my lord, his son is just a child," the Grandfather protested.`
  },
  {
    text: `"We were all children once. Age does not free you from the crimes of your ancestors or the covenant they made with the gods of the land of your forefathers."`
  },
  {
    text: `"Damn you and your covenant." Shifu’s Grandfather took the large and majestic spear that rested against the fireplace mantle, it’s shimmering blade gleamed in the night. He lifted the spear to strike the Ashen One.`
  },
  {
    text: `Dust from the ground rose, crawling up the hands of Shifu’s grandfather, until it melted across his skin, covering his arm, cementing his upper body to the ground, making him one with the earth. He opened his mouth to scream, but nothing came out. He was frozen in time, a pillar of salt and ash.`
  },
  {
    text: `The Ashen One rested its foot on the newly formed statue. "Need I remind you, your ancestors invaded the Cave of Ni’shu, and stole its ancient spear. Ever since then, your people have been bound by the Covenant of the Spear. Your line must now bear the burden to choose a Master of this ancient discipline, and that Master must choose an Heir. If an Heir is not selected the first time I return, I will take a life. The second time I return the following crop season, your line, your people, will be swallowed into the earth."`
  },
  {
    text: `The Ashen One continued, "The Heir you choose will have the power of the earth and wind, a power that is ageless. Starting him early will make even more powerful. You have already paid the first price. Call the child forward, or pay the second one."`
  },
  {
    text: `Young Shifu looked on in pain and admiration. His Grandfather stood up to the gods, stared into the face of death. Perhaps he could face them as well. He stepped forward.`
  },
  {
    text: `"Have mercy on my Grandfather, oh Lord of Earth. I am the child, the boy of which he speaks."`
  },
  {
    text: `The Grandfather was released from the throngs of the earth, collapsed to the ground, and coughed out blood and sand.`
  },
  {
    text: `"Train the boy. He is ready. I sense his heart… is stronger than your son’s."`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/shifu/whatBigEyesYouHave.jpg`
  },
  {
    linebreak: true
  },
  {
    text: `Even after all these years, Shifu still shivered in fear at how the mysterious creature had transfigured into fog and shadow and melted into the night. He stared at the Heir’s lifeless body and the massive spear next to it, a living relic connecting him to the ancient power of the earth and wind.`
  },
  {
    text: `His deep reflection disrupted by the sound of an axe blade barely missing his face, but now staked in the ground. Shifu quickly took up the spear. It twirled in his hand effortlessly, whistling like the call of an old friend.`
  },
  {
    text: `Standing at attention, he inhaled the air of competition, the smell of blood, the decaying flesh of past battles, the stench of death, and the constant fanfare. This was the arena, where facing mortality gives him life. Shifu circled the Challenger, a lion examining its prey. The Challenger swung his axe wildly, Shifu dodged effortlessly.`
  },
  {
    text: `"Not too bad for a young warrior. Left-handed, but that is a ruse. Clearly you are injured, favoring your right leg. You aren't used to the southpaw stance. Your balance is off. The arena can be an unforgiving place, can it not?"`
  },
  {
    text: `The opponent snarled and attacked again, and Shifu slipped away from his axe blade, causing the Challenger to lose balance. Shifu immediately countered with a Three-Spear Slash. The opponent swung the axe again, Shifu ducked, aimed, then in a bright flash, ocean blue and slender as lightning, thrust through the Challenger. Shifu then closed his eyes, lightning struck again, creating a translucent aura around him.`
  },
  {
    text: `Unable to see Shifu, the Challenger recklessly swung his axe. Now fleetfooted, otherwise invisible, Shifu rushed his opponent, whipped his spear around his back, and with a swing to the tendon of the calf, swept his opponent’s feet, knocking him on his back. Shifu then pressed the blade of his spear against the Challenger’s throat.`
  },
  {
    text: `"I would kill you, but the earth has had its fill of blood for the day. Leave my arena and never return."`
  },
  {
    text: `The Challenger dropped his axe and bolted out of the arena. With the weighty spear now across his back, Shifu picked up the dead body of his Heir. Cradling him in his arms, he stumbled out of the arena. The roar of the crowd was a whisper. Walking through the darkness, the frigid night air dried his tears.`
  },
  {
    linebreak: true
  },
  {
    text: `It was dawn when Shifu made his way through the streets of the Qunan capital, beyond the protective walls of the renown towering castle and its beautiful Dragon Garden. Blood dripped onto the stones and flowers of the small interior courtyards and mud houses of the low born - carpenters, fishermen, and blacksmiths.`
  },
  {
    text: `Shifu carried the boy up to his quarters, a small room initially created for servants of the throne. He gently laid the body down and then wrapped it in linen.`
  },
  {
    text: `A shadow crawled across the wall until a whirling wind, dark fog, and dust formed a shape all too familiar. The Ashen One stood before Shifu.`
  },
  {
    text: `"You know why I am here, Spearmaster."`
  },
  {
    text: `"I do. Here is your Heir."`
  },
  {
    text: `The Ashen One kneeled and removed the linen from the dead body. After whispering obscure words from an ancient language, the creature extended itself and bowed down in prayer before the body. The corpse turned to dust and blew away, out of the window and into the midnight air. "He’s one with the earth now." The Ashen One stood and approached Shifu.`
  },
  {
    text: `"So you have no Heir. You know the price."`
  },
  {
    text: `Shifu picked up his spear and assumed his combat stance, ready to strike first. Lightning flashed and arced in the air.`
  },
  {
    text: `"Good luck, spirit. I am not my Grandfather."`
  },
  {
    text: `"No. You are not. You test my mercy, Spearmaster. I will return. And the next time, I will not be alone. You best have your Heir when that time comes."`
  },
  {
    text: `The Ashen One once again transfigured into a torrent of dust, fog and shadow, and rode the wind out of the window.`
  },
  {
    text: `Shifu carefully rested his spear against the wall. He crawled into bed, his heart beating out of his chest. At the rising of the sun, he would start the journey of finding a new heir and the fight to preserve his own life, and that of his line.`
  },
  {
    text: `Shifu closed his eyes and tried to forget his woes until sleep took him.`
  }
];

export default content;
