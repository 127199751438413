const content = [
  {
    title: "The Song of Steel"
  },
  {
    text: `Every morning since I was very young boy I awoke to the chorus of steel against steel, ringing as
    bright and clear as a sunrise over the Long Hou Mountains. I’d look from my bedroom window to see
    Father and Master Shifu sparring in the courtyard. Master Shifu, with his golden spear that blurred
    like a hummingbird as it spun around him. And Father, wielding the mighty greatsword I’d someday
    call my own with a ferocity that made the air quiver with every swing.`
  },
  {
    text: `But that was long ago. Now, Father was a pale shadow of the man who taught me everything I knew.
    "Value honor and justice above all else, boy," he’d tell me. "Tame the storm of your soul, and mastery
    of the blade will come," he’d bellow, in voice that seem to carry the very weight of the Gods.`
  },
  {
    text: `I don’t remember the moment Father was finally worn down by that scheming worm, Pestilus, an
    emissary from Othagur who proposed peace between our lands. All I knew is one day Father... just
    wasn’t Father anymore. Once he was the Dragon King, a warrior without peer, loved and feared by all
    in his kingdom. A mountain of roarous laughter with an appetite as voracious as his fighting spirit.
    But when Father looked at me with eyes clouded with corruption and banished me from the only
    home I ever knew, I’ll never forget the smile that slithered across Pestilus’ face. 
    That smile cut deeper than a thousand blades.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/raigon/Raigon01.jpg`
  },
  {
    text: `The night’s storm seemed to follow me, a torrent of rain and cold. The kind of cold that creeps into
    the cracks of your armor and leathers, chilling you to the bone. I had put almost a half day’s ride
    between my pursuers and I. I was sure Pestilus had sent Angaroth the Reaver to hunt me down and
    silence me forever. And Angaroth was known for his cruelty, not giving up on supposed prey easily.`
  },
  {
    text: `The forest was thick and mist-shrouded. All manner of strange creature noises rang out in the night
    air. I gripped my blade handle, just in case one of them decided that I’d suit for supper. Speaking of
    supper - I hadn’t eaten for over half a day. So the sight of the warm golden glow of candlelight and
    the billowing smoke of a chimney off in the distance was a welcoming sight.`
  },
  {
    text: `The farmhouse was quaint, quite the opposite of the rather grand trappings of the palace, but there
    was a comfort in the simple wooden abode. I knocked on the door and a woman answered. "We’re
    just simple farmers here, nothing to be had or taken but the pigs! And they won’t come without a
    fight. Proud animals they are," she called out behind the door. Her voice was firm, but tinged with
    fear.`
  },
  {
    text: `"Excuse me... um, ma’am, I only wish respite out of the rain and cold," I called out in return. A
    moment, then scuffling and the door opened. The woman was older, around fifty years or so. "You
    have to forgive me, there’s rumor of bandits from Othagur on the prowl, you can’t be too careful.
    Well you don’t look sick-eyed and black tongued. Come in, come in. You’ll catch cold standing out in
    all that mess", she said as she hurried me inside. I smiled at her.  "Thank you, ma’am. Hope I’m not
    too much of a burden," I said as I looked around the humble abode. Animal skins sat on the floor and
    the walls were decorated with heads of bears and stags.`
  },
  {
    text: `The hunter, her husband, sat at the fire in a rocking chair, staring into the flames without moving or
    noticing me. "Oh, don’t mind ole Haemun. He just hasn’t been the same since our son… left", the
    older woman said. "You must be famished. Stews about done. I’m Melga by the way." I sat my blade
    against the wall and took a seat.`
  },
  {
    text: `The woman eyed my blade. "A warrior are you? Our son is - well, was - a warrior himself. He fell in
    battle." She sat the bowl of stew in front of me. "Sorry to hear about your son. But, thank you, I’m
    starved." I said as I dug in.`
  },
  {
    text: `Suddenly there was a hard knock on the door. She walked up to the door and said "Who is it at this
    ungodly hour? Good folk are trying to an honest night’s rest." A dark menacing voice growled back at
    her, "We know the sweet Prince is in there with you. His horse is outside. Bring him out or his head
    won’t be the only one rolling with the pigs." Laughter erupted outside. No doubt, it was Angaroth and
    his men. I grabbed my blade and gestured to Melga. "I’ll handle this. You stay inside and don’t come
    out for any reason."`
  },
  {
    text: `I stepped outside and was greeted by a dozen or so hulking Othagurian men, pale-skinned, tall and
    sinewy, covered in onyx-black, spiny armor. Angaroth was the largest of the group, donning a dark,
    ragged cloak and a beetle-horned helmet. His eyes and spined blade emitted a sickly green-glowing
    aura, transformed by the hideous dark magic of the Blood Priests of Othagur. I could take maybe five
    or six of them, but twelve was too much, even for one of my skill. But I would die fighting, if I had to.
    The rain came down even harder.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/raigon/Raigon02.jpg`
  },
  {
    text: `"I’d turn back now and tell Pestilus you couldn’t find me. Certainly would be better than you never
    returning at all," I called out as I unsheathed my blade. The Othagurians erupted in laughter.
    Angaroth gestured and they hushed immediately. He then pointed his blade at my direction, the
    green glow lighting up the dark. "You truly believe you can defeat me and eleven of Othagur’s most
    violent reavers, all by yourself? You must be truly brave or mad as an imp. But it matters not, after
    we’re done with you we’ll feed your remains to the pigs and burn this little farmhouse to ash."
    Angaroth gestured again, the Othagurians let out a shrill warcry and stampeded towards me.`
  },
  {
    text: `The first warrior thrusted a spear. I parried, spun and slashed upward, dispatching him. The next
    leaped toward me with his blade twirling above him, I somersaulted over him, bringing my blade
    down on two shielded warriors, slicing through shield, armor and flesh, felling them instantly. The
    three other warriors were smarter than to engage me one on one and came at me together, roaring
    and brandishing long dark blades. I blocked, spun back, slashed, blocked another, parried, countered.
    Again and again they slashed and pushed forward. I was becoming overwhelmed when suddenly a
    blur crashed into the warriors, sending them sprawling.`
  },
  {
    text: `Lightning flashed and a hooded, cloaked man stood where the Othagurians fell. I couldn’t see his
    face, but would recognize his weapon anywhere. His golden spear gleamed gloriously in the night.
    The man took off his hood, and my heart leapt at the sight. It was Master Shifu. He nodded at me, his
    eyes, beard and hair the color of steel. Angaroth screamed, "Kill the old fool, I’ll handle our little
    Prince." Master Shifu’s spear spun to life, like a roused beast. It flashed in a deathly arc felling three
    warriors at once.`
  },
  {
    text: `Angaroth leaped towards me, bloody death in his eyes. I sidestepped his slash, and pivoted, shifting
    my weight and spinning for a strike to his body but he blocked and held his ground. Steel grinded
    against steel. Angaroth was strong, ungodly strong. His face was close to mine. His glowing green
    eyes bored into mine. His breath reeked of death and rotted meat. "Pestilus was right," he spat at
    me. "You are as weak and pathetic as your father. As Quna falls, Othagur rises!""
    `
  },
  {
    text: `I shifted my weight again, using Angaroth’s momentum to push him forward, kicked his leg and
    knocked him off balance, then spun and slashed with all my might. Angaroth’s head soared in the air,
    landing in the nearby pigpen.
    `
  },
  {
    text: `I turned to see Shifu striking down the last warrior. He pulled his spear from the dead Othagurian’s
    corpse. "It seems the hunters were the hunted," Shifu said, grimly. "How did you find me, Master
    Shifu, where did you come from?" I said as I approached him.`
  },
  {
    text: `"I traveled home to Quna to see your Father and imagine my surprise at seeing an Othagurian raiding
    party this far from their cursed homeland. I followed them and here we are." I put my hand on his
    shoulder. "Well you arrived just in time, I don’t think I would have lasted alone," I said. "But I do not
    think you would be welcome at the Palace. Pestilus, he has corrupted Father. Turned him against me.
    He… I was banished. Exiled. I have to find a way to save Father, to save all of Quna."`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/raigon/raigon_shifu.jpg`
  },
  {
    text: `Shifu looked at me then, his eyes darkened in thought. "Raigon, I believe I know a way to reclaim
    your home. While I was away I travelled the lands and found refuge and work in fighting in the
    Arenas. There are great warriors from all over Verdia. If we are to reclaim your home, we will need
    their aid. If you can prove yourself in the Arenas, you could gain the loyalty of these warriors. Come
    with me. You are a warrior born, Raigon. Prove your mettle in the Arenas, and we’ll take back Quna
    from the rotted grip of Pestilus."`
  },
  {
    text: `I said my goodbyes to Melga and thanked her and her husband. I mounted Skyward, and followed
    Master Shifu. It seemed our paths were meant to cross. My destiny would take me to the Arenas.
    Shifu was right, I was a warrior born. My journey to take back my home would begin with my first
    memory of it, the ringing of steel against steel.`
  }
];

export default content;
