import App from "./App";
import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router } from "react-router-dom";

export default class AppRouter extends Component {
  history = createHistory();

  render() {
    return (
      <Router history={this.history}>
        <App history={this.history} />
      </Router>
    );
  }
}
