import React, { Component } from "react";

export default class MousePositionFollower extends Component {
  render() {
    const { actualX, actualY, mouseX, mouseY } = this.props.coords;
    return (
      <div
        className="mousePositionFollower"
        style={{
          top: actualY + 50,
          left: actualX - 50,
          display: this.props.active ? "" : "none"
        }}
      >
        <p>
          X: {mouseX} Y: {mouseY}
        </p>
      </div>
    );
  }
}
