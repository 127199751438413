const content = [
  {
    title: "The Living Shroud"
  },
  {
    text: `Deep in the heart of the Na'Gol mountains, beyond the cold rock slate, beyond the endless mines of Anrodor, lives a flame that does not dwindle, does not fade, and never needs to be kindled.`
  },

  {
    image:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ashka/Ashka01.jpg"
  },
  {
    text: `This eternal fire has dwelt there since the time of creation and is said to have intelligence, will, and purpose. The Tioko people have made the heart of the mountain their home and have worshipped this flame since before time memorial. They have come to call this immortal fire The Living Shroud.`
  },
  {
    text: `The seclusive Tioko people are a people of customs and traditions. As they come of age, they are gifted a special mask crafted by the Elder Igniter, the foremost of the fire shapers. These masks are unique, no two being alike. From that day on the mask is always worn, the face of the adolescent Tioko forgotten and only the mask is recognized.`
  },
  {
    image:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ashka/Ashka03.jpg"
  },
  {
    text: `The wearing of the masks represents their devotion to the Living Shroud and is a way for the Tioko people to resemble his image. No one in the living world has seen a Tioko unmasked, and perhaps never will.`
  },
  {
    text: `But this brings us to a young Tioko, with a fate unlike all the others. A young boy named Ashka. You see Ashka was different - he was born a mute. Since the moment he was born, he never uttered a sound at all. He only looked up at his cavernous world with wide golden eyes, which were of immense beauty.`
  },

  {
    text: `Golden eyes are very rare among the Tioko and said to be blessed by the Living Shroud itself. "Sight of the Shroud" they called it. Word spread of one sacred to the Shroud and young Ashka was all but worshipped himself.`
  },
  {
    image:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ashka/Ashka02.jpg"
  },
  {
    text: `This was all well and good until the day of his coming of age, when young Ashka would inherit his igniter powers from The Living Shroud and join his friend and family in shaping the mountain. But this is not what happened.`
  },
  {
    text: `When Ashka was brought before the Living Shroud and given his mask, the Shroud did not awake. It lay dormant, fiery smouldering yet subdued. Ashka's people looked on in bewilderment. The Living Shroud did not imbue Ashka with the Mountain's Fire. Ashka, once said to be blessed by the Shroud, was cursed by it.`
  },
  {
    text: `In the pitiless tradition of the Tioko, Ashka was cast out of the mountain. Expelled through one of the countless holes leading to the cold outside world, away from the warmth and familiarity of the Heart.
    `
  },
  {
    text: `Ashka was mute, but he was not dumb. He knew what happened. He knew he was cursed. Cast out by all he loved and all he knew. He looked up to see a vast, dull, blue ceiling above him. The rocky ground was strewn with strange green, needlelike fungus. In the distance, strange looking stalagmites grew out of the land, brown and leathery in texture, with green plants proliferating the upper roots. Ashka was lost, and alone in an alien world. All he could do was walk forward, and see what he could see. `
  },
  {
    text: `Ashka soon found himself in the ruins of a strange city. It must have once been a grand and majestic place, the young Tioko thought to himself. The streets were lined with metal statues of tall, lean, maskless people.`
  },
  {
    text: `Their hands were stretched out toward the mountain. An ethereal wind blew through the city, and the tattered red flags flapped like wounded birds. Ghostly howls echoed out that chilled Ashka to the bone. Spectral wraiths swam around him and flew off, howling their piercing cries. Like himself, Ashka reasoned, this place was cursed.
    `
  },
  {
    text: `Ashka slowed, weak and weary. The Tioko did not take in sustenance like regular people but fed on the warmth of the Living Shroud. Without it, Ashka was certain he would soon die. He entered a long-abandoned house, that was dark and small enough to remind him of the home he was cast from and curled up in a corner and waited to die.`
  },
  {
    text: `But Ashka did not die. Instead, he dreamed.
    `
  },
  {
    text: `Ashka stood before the Living Shroud, fully ablaze in all his fiery glory. Ashka, for the first time in all his life, felt a strange bubbling in his throat, and it erupted. Ashka shouted, "Why!? Why you curse Ashka!?" The Shroud smiled at the young Tioko, its face was wild, primal and savage. Ashka could see how the masks of his people reflected this visage. The Shroud spoke then, its voice roared like an inferno, yet was soothing to Ashka’s ears.
    `
  },
  {
    text: `"Young Tioko child, I know your pain. But take heart. Your pain now will save your people from an eternity of torment. It was my plan to have you cast out, for the role you will play in this world's events cannot be accomplished from the warmth of my Mountain. Take heart, Tioko child. I am with you. You carry the Heart of the Mountain in your chest."
    `,
    style: {
      fontStyle: "italic"
    }
  },
  {
    text: `The Living Shroud laughed then, a tremendous and terrible laugh, and the fires of the Shroud consumed Ashka's sight.`
  },
  {
    text: `Ashka awoke then, cold and alone. He looked down at his hands. He felt an itch on his fingers. A strange heat rose from them. He snapped his fingers, and a single flame sparked and lit. Ashka smiled as a single tear fell from his golden, beautiful eyes hidden by his mask. He uttered his first word as he looked transfixed at the orange glow in his palm.
    `
  },
  {
    text: `"Fire"`,
    style: {
      marginLeft: "46%",
      color: "red"
    }
  },
  {
    image:
      "https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/ashka/cute_boi.jpg"
  }
];

export default content;
