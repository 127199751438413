import React, { Component } from "react";
import playSound from "../../_shared/data/sounds";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import "./css/Pin.css";

const championPinSize = 60; // in pixels
const locationPinSize = { width: 54, height: 79 }; // in pixels

const smallModalHeight = 228;
const smallModalWidth = 380;
const smallChampionModalHeight = 108;
const smallChampionModalWidth = 327;

export default class Pin extends Component {
  state = {
    photoIndex: 0,
    displayArtwork: false
  };

  toggleArtwork = () =>
    this.setState({ displayArtwork: !this.state.displayArtwork });

  soundFuncs = {
    hover: () => {
      if (!this.props.showSmallModal && !this.props.isMuted) {
        playSound("hover");
      }
    },
    click: () => {
      if (this.props.toggleModal("smallModal")) {
        if (!this.props.isMuted) {
          playSound("click");
        }
      }
    },
    open: id => () => {
      if (id) {
        if (!this.props.isMuted) {
          playSound("open");
        }
        this.props.openStory(id)();
      }
    }
  };
  render() {
    const { photoIndex, displayArtwork } = this.state;
    let pinContent = null;
    let images = null;

    if (this.props.location) {
      images = this.props.location.artwork;
      pinContent = (
        <LocationPin
          displayArtwork={displayArtwork}
          soundFuncs={this.soundFuncs}
          toggleArtwork={this.toggleArtwork}
          champions={this.props.champions}
          location={this.props.location}
          showSmallModal={this.props.showSmallModal}
          x={this.props.x}
          y={this.props.y}
          pinUrl={
            "https://cdn.battlerite.com/www3/images/static/lore/map/br_map_pin.png"
          }
        />
      );
    }
    if (this.props.arena) {
      images = this.props.arena.artwork;
      pinContent = (
        <LocationPin
          displayArtwork={displayArtwork}
          soundFuncs={this.soundFuncs}
          toggleArtwork={this.toggleArtwork}
          location={this.props.arena}
          showSmallModal={this.props.showSmallModal}
          x={this.props.x}
          y={this.props.y}
          pinUrl={
            "https://cdn.battlerite.com/www3/images/static/lore/map/arena_pin_small.png"
          }
        />
      );
    }
    if (this.props.championContent) {
      pinContent = (
        <ChampionPin
          displayArtwork={displayArtwork}
          soundFuncs={this.soundFuncs}
          championContent={this.props.championContent}
          showSmallModal={this.props.showSmallModal}
          x={this.props.x}
          y={this.props.y}
        />
      );
    }

    let lightbox =
      images && displayArtwork ? (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={this.toggleArtwork}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length
            })
          }
        />
      ) : null;

    return (
      <div>
        {pinContent}
        {lightbox}
      </div>
    );
  }
}

const LocationPin = props => {
  const hasChampions = props.champions ? props.champions.length > 0 : null;
  const modalWidthDiff = 107;

  const {
    x,
    y,
    showSmallModal,
    soundFuncs,
    champions,
    toggleArtwork,
    location,
    pinUrl
  } = props;

  return (
    <div className="mapInteractable">
      <img
        src={pinUrl}
        draggable="false"
        alt="pin"
        style={{
          position: "relative",
          left: `${x - locationPinSize.width / 2 + 1}px`,
          top: `${y -
            locationPinSize.height +
            5 -
            (props.showSmallModal ? -1 : 0)}px`
        }}
        onClick={soundFuncs["click"]}
        onMouseOver={soundFuncs["hover"]}
      />
      <div
        className="smallModal"
        style={
          !showSmallModal
            ? {
                position: "absolute",
                overflow: "hidden",
                clip: "rect(0 0 0 0)",
                height: " 1px; width: 1px",
                margin: "-1px",
                padding: 0,
                border: 0
              }
            : {
                position: "relative",
                left: `${x -
                  (hasChampions
                    ? smallModalWidth
                    : smallModalWidth - modalWidthDiff) /
                    2 +
                  1}px`,
                top: `${y -
                  (smallModalHeight + locationPinSize.height + 50)}px`,
                width: `${
                  hasChampions
                    ? smallModalWidth
                    : smallModalWidth - modalWidthDiff
                }px`,
                height: `${smallModalHeight}px`,
                backgroundImage: hasChampions
                  ? `url("https://cdn.battlerite.com/www3/images/static/lore/map/Location.png")`
                  : `url("https://cdn.battlerite.com/www3/images/static/lore/map/smolBox.png")`
              }
        }
      >
        <div className="smallModalContent">
          <h4>{location.name}</h4>
          <div>
            <p
              style={{
                height: location.artwork ? "130px" : "145px"
              }}
            >
              {location.text}
            </p>
            {location.artwork ? (
              <u className="fancyButton" onClick={toggleArtwork}>
                View Artwork ({location.artwork.length})
              </u>
            ) : null}
          </div>
        </div>
        {hasChampions ? (
          <div className="smallModalChampions">
            <h4>Champions</h4>
            {champions.map((champion, index) => (
              <img
                src={`https://cdn.battlerite.com/www3/images/static/lore/shared/portraits/${
                  champion.name
                }.png`}
                alt={champion.name}
                draggable={false}
                key={index}
                onClick={soundFuncs["open"](champion.storyId)}
                className={champion.storyId ? "" : "grey"}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const ChampionPin = props => {
  const { x, y, showSmallModal, championContent, soundFuncs } = props;
  return (
    <div className="mapInteractable championPin">
      <div
        className="championPinContainer"
        style={{
          height: championPinSize + 12,
          width: championPinSize * 2 + 6,
          backgroundColor: "white",
          position: "relative",
          left: `${x - championPinSize}px`,
          top: `${y - championPinSize / 2 - (showSmallModal ? -1 : 0) - 7}px`
        }}
        onClick={soundFuncs["click"]}
      >
        <img
          src={`https://cdn.battlerite.com/www3/images/static/lore/shared/portraits/${
            championContent.name
          }.png`}
          draggable="false"
          alt="pin"
          style={{
            position: "relative",
            top: "6px",
            left: "8px",
            height: championPinSize
          }}
          onMouseOver={soundFuncs["hover"]}
        />
      </div>

      <div
        className="smallChampionModal"
        style={
          !showSmallModal
            ? {
                position: "absolute",
                overflow: "hidden",
                clip: "rect(0 0 0 0)",
                height: " 1px; width: 1px",
                margin: "-1px",
                padding: 0,
                border: 0
              }
            : {
                left: `${x - 327 / 2 + 6}px`,
                top: `${y - 108 - 90}px`,
                width: `${smallChampionModalWidth}px`,
                height: `${smallChampionModalHeight}px`
              }
        }
      >
        <img
          src={championContent.image}
          style={{
            width: `${smallChampionModalWidth - 4}px`,
            height: `${smallChampionModalHeight - 4}px`,
            position: "absolute",
            top: "2px",
            left: "2px"
          }}
          draggable={false}
          alt="pinPreviewImage"
        />
        <div
          className="smallChampionModalContent"
          style={championContent.pinStyle}
        >
          <h3>{championContent.title}</h3>
          {championContent.storyId !== 999 ? (
            <p onClick={soundFuncs["open"](championContent.storyId)}>
              Read the story
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};
