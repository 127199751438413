const content = [
  { title: "Talon Island" },
  {
    text: `Part 1 - Xoranvar’s Heart`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `There is a place unlike any other on the surface of Verdia. It was where I chose to create the first spark of life on this world, my first glimmer of inspiration. It was beautiful in its imperfection.`
  },
  {
    text: `I am Xoranvar, God of Wisdom. As a reminder of the young artist I was, I created a unique flower to bloom here. Nowhere else on Verdia will you see a Skylock. It is mine and mine alone, as is this island. This island is like my firstborn - my home, my sanctuary. I will treasure it for eternity.`
  },
  {
    text: `I watched the world from the skies, soaring through the clouds in the material form of a majestic Storm Wyvern. It was fitting, as the sky and the heavens were my prime domains. Lithe, graceful, and powerful, I surveyed all in my kingdom. And though it felt like mere minutes, centuries passed.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/talonIsland/dogDragonWyvernBus.jpg`
  },
  {
    text: `The lush, vibrant world that my brothers and sisters and I created began to teem with life. My island, sacred and blessed, also attracted growth. People built temples and shrines in my honor. All was well.`
  },
  {
    text: `In our childlike enthusiasm and our pride, my siblings and I desired to show our creations to our Father. We still remembered him fondly as Amargah the Father of All, even though we had been separated from him for millennia. We sought to summon him into our world.`
  },
  {
    text: `We know now, this was folly.`
  },
  {
    text: `It was no small undertaking, but finally we were ready. As we performed the ritual of summoning though, Verdia’s greatest doom was realized. Amargah emerged, and he was no longer our loving father but a twisted shadow of himself. Corrupted and sick and in a mindless furor.`
  },
  {
    text: `As Amargah raged, Verdia was plunged into fire and darkness. Many of my siblings were slain or lost. With great sacrifice and effort we drove our father back into the realm beyond from where he came. Those of us that survived followed him to root out this corruption. We do not know if we will ever return to our precious world.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/talonIsland/isThisANewPestilusSkin.jpg`
  },
  {
    text: `Part 2 - The Daharin Fisherman`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `The river folk of Daharin are of a hardy and stubborn stock. We are not easily roused or frightened, but when Headhunter raiders struck our small settlement they hit fast and without warning.`
  },
  {
    text: `Headhunters! Not in five hundred years had they been seen. They were thought to have perished in the fires that rained when the gods fought in the heavens. The river folk had recovered after the long night that followed, and the Headhunters had seemed to disappeared.`
  },
  {
    text: `But here they were, falling on the village in a snarling horde. Many of us died and those who lived were driven from our homes. We made our way to the only place of refuge, the Monastery of Resonance, the seat of power for the faith of The Symphony.`
  },
  {
    text: `I, Gediron Moss, a mere fisherman and swordsman of no import, quickly found myself leading this band of refugees.`
  },
  {
    text: `The Monastery accepted us with open arms but they did not have the room to accommodate us forever. We were stranded without a place to call our own. But then I met Talaia, an ardent young priestess that I couldn’t help take a liking to.`
  },
  {
    text: `Talaia told me of an island in the middle of the great river that runs through the length of Daharin, a place lost to the mist and time. She said it was sacred to Xoranvar, the ancient god of her faith. I was not a man of faith myself, there had been no gods there to answer our prayers when the Headhunters attacked. But Talaia had real hope in her eyes. And I had not seen hope in a long time.`
  },
  {
    text: `Talaia told me that she and a number of her fellow believers planned to seek this island but lacked  someone to navigate the ship. I was a seasoned fisherman, she said that my coming was a blessing from Xoranvar. I decided to help, but only if this island could be settled by my people. She agreed, and soon we set off.`
  },
  {
    text: `We sailed for three days and three nights and all we found was mist and fog. Finally on the fourth day, just as we were deciding to turn around and head back, the haze parted and we saw it: a lush, green island dominated by a steep mountain the shape of a talon.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/talonIsland/TalonIsland03.jpg`
  },
  {
    text: `There was peculiar atmosphere that lay over the island. As we landed and docked, we found ruins of buildings and temples abandoned long ago. Judging from the ancient murals it seemed Talaia had indeed found the sacred island of her ancient god.`
  },
  {
    text: `We settled and made the island our home. As time went by, the river folk started fishing and working the fields again. Talaia and her priests erected a church for them to worship in. Eventually, Talaia and I were married and the townsfolk appointed me to Baron over the island. For many years, life was good.`
  },
  {
    text: `But it was not to last.`
  },
  {
    text: `Part 3 - The Blood Priestess`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `The villagers found me, dragged me from the sea, and resuscitated me. They said my lungs had already been filled with river water.`
  },
  {
    text: `They couldn’t understand how I didn’t drown. They didn’t know that we of Othagur are born from the ocean, are one with the sea. The blood that runs through my veins is the very same as Tiermok, the God of the Sea, himself.`
  },
  {
    text: `I lied and I told them I had escaped from a slave ship. They bought it. Although they did stare and linger too long at my unnaturally pale skin. My emerald eyes and jet black hair proved to be even more of a distraction, especially for the men among them.`
  },
  {
    text: `As the villagers escorted me through town, I saw the work of Lord Pestilus was already well underway. Bodies piled up in stinking heaps. Entire shops and houses were closed with the words “Quarantine,” written on them. The plague had done its work. All it took was a little blood magic in the river and the fish were tainted.`
  },
  {
    text: `The villagers professed what I already knew; the plague seemed to come from nowhere and ravaged them like a forest fire. They’d just come from a vigil for the old revered High Priestess, the wife of the Baron, who recently passed from the plague. All was moving according to plan. Now it was time to play my part.`
  },
  {
    text: `With feigned weakness, I inquired about the plague. I then told the villagers that my art was healing and perhaps I could aid them where their priests could not. They took me to their most sacred place, the ancient Temple of Xoranvar.`
  },
  {
    text: `The priests and priestesses were in an awful way. They were fraught with sadness and desperation. I could not blame them. Their sacred High Priestess had succumbed to sickness, and they felt their god had cursed them with this plague. I almost felt a tinge of guilt knowing my own actions were at the heart of their woes. But I had to carry out my mission. For blood and bile, for the glory of Othagur.`
  },
  {
    text: `The Priest confessed that they were afraid they had somehow angered their god. No healing magic they performed seemed to have any effect on the plague. I told them that I had seen such disease before. There were rituals, dark rituals, that could be performed that could cure it.`
  },
  {
    text: `They responded predictably. They called me witch and heretic. They sent me to my quarters and told me I would be on my way as soon as I recovered. But the seed had been planted. Now it was only a matter of time.`
  },
  {
    text: `It took longer than I expected. A week I sat in my quarters, more a prisoner than a guest. A knock and then the door opened. A priest and priestess stood in the doorway. Deep lines etched their faces, their eyes were bloodshot and raw. Desperation could make you do things you never believed you could.`
  },
  { text: `“Show us your ritual, witch.”` },
  {
    text: `I had them take me to the deepest, darkest part of their island. They took me to a crypt far below the temple. For blood magic to work, you need complete and utter darkness. As we entered, I could sense that something... was not right. There was dark energy at play there, barely discernible. But there was no time to dwell on that, I had a job to do.`
  },
  {
    text: `The priests thought I would deliver them from their affliction, but this was to be the final part of the plan. This was not a spell of healing, but a portal to Othagur, where our troops were standing ready to invade. The island would serve perfectly as a secret foothold in the heart of this weak land.`
  },
  {
    text: `I drew the blood from the two priests, then my own. I performed the ritual as I had done a hundred times before. But this time something different happened. As the portal opened it was not our soldiers that poured through the opening, it was the source of that dark energy I had felt. A whispering Void.`
  },
  {
    text: `A Blood Priestess of Othagur recognizes true power when she feels it. I could not stop myself. I held the portal open and the Void came screaming through it.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/talonIsland/TalonIsland04.jpg`
  },
  {
    text: `Part 4 - Xoranvar’s Return`,
    style: {
      fontStyle: "bold",
      fontWeight: "800"
    }
  },
  {
    text: `It is said that few know the fury of a god and live to tell the tale. Such was the rage of Xoranvar as he returned, beaten and weary, from a realm beyond even his understanding.`
  },
  {
    text: `It was difficult to say how long Xoranvar and his siblings had been there, in the twisted dimension they had followed their father into. Time had been a blur in that churning hellscape. It was as if everything there was a mockery of life itself, everything a sick parody of the beauty of Verdia. This nightmare realm had fought them every step, and they had fought back just as hard. One by one, the gods had been separated from each other until Xoranvar was all alone. Still, he had pressed on.`
  },
  {
    text: `Finally he found the source of the corruption. A black nothingness, an antithesis of creation, pulsating and growing like a tumor. An undulating, whispering Void.`
  },
  {
    image: `https://cdn.battlerite.com/www3/images/static/lore/shared/stories/images/talonIsland/TalonIsland05.jpg`
  },
  {
    text: `Xoranvar could not remember how he had made it back to Verdia. He had opened his eyes and found himself returned, disoriented as if roused from a bad dream. In need of rest from half a millennia of darkness and death, Xoranvar retreated back to his old sanctuary of Skylocks, to what was now known as Talon Island. He found his island still inhabited. The familiarity comforted him at first, but soon he realized that something was very wrong.`
  },
  {
    text: `A remnant of the same Void he had just escaped was there, leaking into the world, corrupting and changing the people there. The same corruption that claimed his Father Amargah, that he had fought for five hundred years, brought to his sanctuary by his own children! In a rage only a god could have, Xoranvar summoned a spectral wind that wiped out both the seat of corruption and all the people on the island.`
  },
  {
    text: `As the fires of his rage died down, Xoranvar was left with a new resolve. This corruption, this Void would not stop it would continue to grow, to seep into his world. He would fight it, but he knew he could not do it alone. He would have to raise an army.`
  },
  {
    text: `Xoranvar would gather champions from all over the world and pit them against each other. The island that was once his sanctuary would now host this Royale. Those who were victorious would be his chosen warriors, for a battle that may have the very fate of Verdia at stake.`
  }
];

export default content;
