import React, { Component } from "react";
import "./css/SettingsModal.css";
import { CompactPicker } from "react-color";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

export default class SettingsModal extends Component {
  lineHeightOptions = [
    { value: 1, label: 1 },
    { value: 1.5, label: 1.5 },
    { value: 2, label: 2 },
    { value: 2.5, label: 2.5 },
    { value: 3, label: 3 }
  ];
  render() {
    return (
      <div className="settingsModal">
        <div className="settings-content">
      
          <div className="settings">
              <div className="settings-top">
              <div className="flex-column" style={{width: "130px"}}>

              <label>Line Height</label>
              <Dropdown
                options={this.lineHeightOptions}
                onChange={d => {
                  const settings = this.props.settings;
                  settings.lineHeight = d;
                  this.props.updateSettings(settings);
                }}
                value={this.lineHeightOptions[1]}
                placeholder="Select an option"
              />
            </div>
            <div className="undo-svg">
            <svg  onClick={this.props.resetSettings} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path  d="M0 0h24v24H0z" fill="none"/><path  fill="white"d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"/></svg>
</div>
</div>


            <div className="flex-column">
              <label>Background Colour</label>
              <CompactPicker
                color={this.props.settings.rgb}
                onChangeComplete={({ rgb }) => {
                  const settings = this.props.settings;
                  settings.rgb = rgb;
                  this.props.updateSettings(settings);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
