import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import SettingsModal from "../../map/components/SettingsModal";
import gtag from "../util/analytics";

import "./css/Story.css";

export default class Story extends Component {
  blockUtil = {
    image: block => {
      return (
        <img
          className="story-image"
          style={block.style}
          src={block.image}
          alt={`${this.props.story.champion}-story`}
          draggable={false}
        />
      );
    },
    text: block => {
      return (
        <div className="story-text" style={block.style}>
          {block.text}
        </div>
      );
    },
    title: block => {
      return (
        <div>
          <h1 className="story-title" style={block.style}>
            {block.title}
          </h1>
          <h2 className="authored-by">By Christopher Harrow</h2>
        </div>
      );
    },
    box: block => {
      return (
        <div className="box" style={block.style}>
          {block.components.map((innerBlock, index) => (
            <div key={index}>{this.generateBlock(innerBlock)}</div>
          ))}
        </div>
      );
    },
    italics: block => {
      return (
        <div className="italics-box">
          {block.italicizedBlocks.map((block, index) => {
            return (
              <span style={block.style} key={index}>
                {block.text}
              </span>
            );
          })}
        </div>
      );
    },
    linebreak: block => {
      return (
        <div className="line-break">
          <hr />
        </div>
      );
    }
  };

  generateBlock = block => {
    if (block.text) return this.blockUtil["text"](block);
    else if (block.title) return this.blockUtil["title"](block);
    else if (block.image) return this.blockUtil["image"](block);
    else if (block.components) return this.blockUtil["box"](block);
    else if (block.italicizedBlocks) return this.blockUtil["italics"](block);
    else if (block.linebreak) return this.blockUtil["linebreak"](block);
  };

  handleScrollStart = () => gtag("event", "story_scroll");

  render() {
    if (!this.props.story) {
      return null;
    }
    const { r, g, b } = this.props.settings.rgb;

    const blocks = this.props.story.content.map((block, index) => (
      <div
        className="block"
        key={index}
        style={{ lineHeight: this.props.settings.lineHeight.value }}
      >
        {this.generateBlock(block)}
      </div>
    ));

    return (
      <div
        className="story"
        style={{
          background: `linear-gradient( to bottom, rgb(39, 41, 47) 0%, rgb(${r},${g}, ${b}) 100%) `
        }}
      >
        <div className="story-header">
          <svg
            onClick={this.props.toggleSettings}
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 24 24"
            className="settingsSvg"
          >
            <path
              d="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
              fill="white"
            />
            <path d="M0 0h20v20H0V0z" fill="none" />
          </svg>
          {this.props.closeModal ? (
            <svg
              onClick={this.props.closeModal}
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                fill="white"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          ) : null}
        </div>

        <Scrollbars onScrollStart={this.handleScrollStart}>
          <div className="blocks">{blocks} </div>

          <img
            className="story_footer"
            src="https://cdn.battlerite.com/www3/images/static/lore/map/br_story_footer.png"
            alt="fancy_footer"
            draggable={false}
          />
        </Scrollbars>

        {this.props.displaySettings ? (
          <SettingsModal
            settings={this.props.settings}
            toggleSettingsModal={this.props.toggleSettings}
            updateSettings={this.props.updateSettings}
            resetSettings={this.props.resetSettings}
          />
        ) : null}
      </div>
    );
  }
}
