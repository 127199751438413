function between(x, min, max) {
  return x >= min && x <= max;
}

export function betweenRange(x, y, diff) {
  return between(x, y - diff, y + diff);
}

//pass it generated coord
//pass it the current pins coord
//check if they are within a range of 100 of each other.
