import React, { Component } from "react";
import "./css/HomeApp.css";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

export default class HomeApp extends Component {
  state = {
    storyId: -1,
    isModalOpen: false,
    homeWidth: undefined,
    hoverState: -1
  };

  handleResize = () => {
    this.setState({
      homeWidth: window.innerWidth - this.props.sideBarWidth
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const maxHeight = this.props.isMobile ? 95 : 100;
    const mapSpread = (
      <section
        id="home--map"
        onMouseEnter={() => this.setState({ hoverState: 1 })}
        onMouseLeave={() => this.setState({ hoverState: -1 })}
        style={
          !this.props.isMobile
            ? this.state.hoverState === 1
              ? { height: `${0.7 * maxHeight}vh` }
              : this.state.hoverState === 2
              ? { height: `${0.4 * maxHeight}vh` }
              : { height: `${0.6 * maxHeight}vh` }
            : this.state.hoverState === 2
            ? { height: `${0.3 * maxHeight}vh` }
            : this.state.hoverState === 1
            ? { height: `${0.6 * maxHeight}vh` }
            : { height: `${0.4 * maxHeight}vh` }
        }
      >
        <div className="section-content">
          <div className="section-center">
            <div className="section-text">
              <h1>Explore the World of Battlerite</h1>
              <p>See where your favourite champions are from!</p>
            </div>
            <div>
              <div
                className="fancyButton"
                style={
                  this.state.hoverState === 2
                    ? { height: "0px", opacity: "0" }
                    : { height: "2.5em", opacity: "1" }
                }
                onClick={
                  this.props.isMobile
                    ? null
                    : () => this.props.history.push("/map")
                }
              >
                {this.props.isMobile ? "Unavailable on Mobile" : "View Map"}
              </div>
            </div>
          </div>
        </div>
      </section>
    );

    const storySpread = (
      <section
        id="home--stories"
        onMouseEnter={() => this.setState({ hoverState: 2 })}
        onMouseLeave={() => this.setState({ hoverState: -1 })}
        style={
          !this.props.isMobile
            ? this.state.hoverState === 2
              ? { height: `${0.6 * maxHeight}vh` }
              : this.state.hoverState === 1
              ? { height: `${0.3 * maxHeight}vh` }
              : { height: `${0.4 * maxHeight}vh` }
            : this.state.hoverState === 1
            ? { height: `${0.4 * maxHeight}vh` }
            : this.state.hoverState === 2
            ? { height: `${0.7 * maxHeight}vh` }
            : { height: `${0.6 * maxHeight}vh` }
        }
      >
        <div
          className="transparent-overlay"
          style={
            !this.props.isMobile
              ? this.state.hoverState === 2
                ? { height: `${0.6 * maxHeight}vh` }
                : this.state.hoverState === 1
                ? { height: `${0.3 * maxHeight}vh` }
                : { height: `${0.4 * maxHeight}vh` }
              : this.state.hoverState === 1
              ? { height: `${0.4 * maxHeight}vh` }
              : this.state.hoverState === 2
              ? { height: `${0.7 * maxHeight}vh` }
              : { height: `${0.6 * maxHeight}vh` }
          }
        >
          <div className="section-content">
            <div className="section-center">
              <div className="section-text">
                <h1>Read our Stories...</h1>
                <p>New chapter every other week!</p>
              </div>
              <div>
                <div
                  className="fancyButton"
                  style={
                    this.state.hoverState === 1
                      ? { height: "0px", opacity: "0" }
                      : { height: "2.5em", opacity: "1" }
                  }
                  onClick={() => this.props.history.push("/stories")}
                >
                  Read Stories
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );

    return (
      <ReactCSSTransitionGroup
        transitionName="updateFade"
        transitionEnterTimeout={300}
        transitionLeave={false}
        transitionAppear={true}
        transitionAppearTimeout={300}
      >
        <div
          id="home"
          style={{
            width: this.props.isMobile ? "100vw" : this.state.homeWidth,
            height: `${maxHeight}vh`
          }}
        >
          {this.props.isMobile ? storySpread : null}
          {mapSpread}
          {!this.props.isMobile ? storySpread : null}
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}
