import React, { Component } from "react";
import { Route } from "react-router-dom";
import { isMobile, isTablet, browserName } from "react-device-detect";
import HomeApp from "./home/components/HomeApp";
import MapApp from "./map/components/MapApp";
import StoriesApp from "./stories/components/StoriesApp";
import SideBar from "./map/components/SideBar";
import PityScreen from "./map/components/PityScreen";
import Redirect from "./map/components/Redirect";
import gtag from "./_shared/util/analytics";

import MyContext from "./MyContext";

class MyProvider extends Component {
  state = {
    isMuted: false,
    displaySettings: false,
    sideBarWidth: 60,
    sideBarHeight: 50, //for mobile
    isMobile: false,
    settings: {
      rgb: {
        r: 6,
        g: 27,
        b: 35
      },
      lineHeight: { value: 1.5, label: 1.5 }
    }
  };

  updateData = valueName => data => {
    this.setState({ [valueName]: data });
  };

  handleResize = () => {
    const isOnlyMobile = (isMobile && !isTablet) || window.innerWidth <= 500;
    this.setState({ isMobile: isOnlyMobile });
  };

  resetSettings = () => {
    this.setState({
      settings: {
        rgb: {
          r: 6,
          g: 27,
          b: 35
        },
        lineHeight: { value: 1.5, label: 1.5 }
      }
    });
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };

  render() {
    return (
      <MyContext.Provider
        value={{
          isMuted: this.state.isMuted,
          displaySettings: this.state.displaySettings,
          sideBarWidth: this.state.sideBarWidth,
          sideBarHeight: this.state.sideBarHeight,
          isMobile: this.state.isMobile,
          toggleSounds: () => {
            this.updateData("isMuted")(!this.state.isMuted);
          },
          toggleSettingsModal: () => {
            this.updateData("displaySettings")(!this.state.displaySettings);
          },
          updateSettings: this.updateData("settings"),
          resetSettings: this.resetSettings,
          settings: this.state.settings
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

export default class App extends Component {
  supportedBrowsers = [
    "Chrome",
    "Firefox",
    "Opera",
    "Safari",
    "Mobile Safari",
    "Samsung Browser",
    "Facebook",
    "Vivaldi"
  ];

  state = {
    ignoreWarning: false
  };

  componentDidMount() {
    gtag("js", new Date());
    gtag("config", "UA-73755675-26");
  }

  ignoreWarning = () => this.setState({ ignoreWarning: true });
  render() {
    if (
      this.supportedBrowsers.includes(browserName) ||
      this.state.ignoreWarning
    ) {
      return (
        <div className="App">
          <MyProvider>
            <MyContext.Consumer>
              {context => (
                <div>
                  <SideBar
                    history={this.props.history}
                    toggleSounds={context.toggleSounds}
                    isMuted={context.isMuted}
                    isMobile={context.isMobile}
                    width={context.sideBarWidth}
                    height={context.sideBarHeight}
                    settings={context.settings}
                    toggleSettingsModal={context.toggleSettingsModal}
                  />
                </div>
              )}
            </MyContext.Consumer>
            <Route
              path="/"
              exact
              component={() => (
                <MyContext.Consumer>
                  {context => (
                    <HomeApp
                      history={this.props.history}
                      sideBarWidth={context.sideBarWidth}
                      sideBarHeight={context.sideBarHeight}
                      isMuted={context.isMuted}
                      isMobile={context.isMobile}
                    />
                  )}
                </MyContext.Consumer>
              )}
            />
            <Route
              path="/map"
              exact
              component={() => (
                <MyContext.Consumer>
                  {context =>
                    !context.isMobile ? (
                      <MapApp
                        sideBarWidth={context.sideBarWidth}
                        sideBarHeight={context.sideBarHeight}
                        isMuted={context.isMuted}
                        isMobile={context.isMobile}
                        settings={context.settings}
                        toggleSettings={context.toggleSettingsModal}
                        updateSettings={context.updateSettings}
                        displaySettings={context.displaySettings}
                        resetSettings={context.resetSettings}
                      />
                    ) : (
                      <Redirect history={this.props.history} />
                    )
                  }
                </MyContext.Consumer>
              )}
            />
            <Route
              path="/stories/:champ?"
              exact
              component={() => (
                <MyContext.Consumer>
                  {context => (
                    <StoriesApp
                      sideBarWidth={context.sideBarWidth}
                      sideBarHeight={context.sideBarHeight}
                      isMuted={context.isMuted}
                      isMobile={context.isMobile}
                      settings={context.settings}
                      toggleSettings={context.toggleSettingsModal}
                      updateSettings={context.updateSettings}
                      displaySettings={context.displaySettings}
                      resetSettings={context.resetSettings}
                    />
                  )}
                </MyContext.Consumer>
              )}
            />

            <Route
              component={() => (
                <MyContext.Consumer>
                  {context => (
                    <Redirect
                      isMobile={context.isMobile}
                      history={this.props.history}
                    />
                  )}
                </MyContext.Consumer>
              )}
            />
          </MyProvider>
        </div>
      );
    } else {
      return (
        <div>
          <PityScreen
            supportedBrowsers={this.supportedBrowsers}
            browserName={browserName}
            ignoreWarning={this.ignoreWarning}
          />
        </div>
      );
    }
  }
}
